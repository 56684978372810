export const apiResponse = () => {
  return {
    resData: {
      data: undefined,
      message: undefined,
      errors: [
        {
          field: undefined,
          message: undefined
        }
      ],
      meta: {
        http_code: undefined,
        limit: undefined,
        total: undefined,
        offset: undefined
      }
    },
    resStatus: undefined
  }
}
