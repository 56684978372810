import { apiPost } from 'api/api-post'
import { urls } from 'api/api-url'
import { BACK_OFFICE } from 'constant'

export const postLoginAdmin = async (data) => {
  return apiPost({
    url: urls.auth.login,
    data: { email: data?.email, password: data?.password, portal: BACK_OFFICE }
  })
}
export const postForgotPassword = (email) => {
  return apiPost({
    url: urls.auth.forgotPassword,
    data: {
      email: email
    }
  })
}
export const postResetPassword = (token, password, confirmPassword) => {
  return apiPost({
    url: urls.auth.resetPassword,
    data: {
      token: token,
      password: password,
      password_confirmation: confirmPassword
    }
  })
}
export const postConfirmOtp = (token, code) => {
  return apiPost({
    url: urls.auth.confirmOtp,
    data: {
      token: token,
      code: code
    }
  })
}
