import ProfilePlatformContent from 'components/organisms/users/profile/ProfilePlatformContent'
import SidebarProfile from 'components/organisms/users/profile/SidebarProfile'
import { CONTACTS_CONTENT, PROFILE_CONTENT, SECURITY_CONTENT } from 'constant'
import React, { useState } from 'react'

const ProfilePlatformPage = () => {
  const [currentContent, setCurrentContent] = useState(PROFILE_CONTENT)

  function handleContent(value) {
    setCurrentContent(value)
  }
  return (
    // Wrapper grey
    <div className="w-full h-full flex flex-grow bg-[#EDEDEF] rounded-2xl">
      <div className="grid grid-cols-[300px_1fr] w-full h-full">
        {/* Sidebar */}
        <SidebarProfile handleContent={handleContent} currentContent={currentContent} />
        {/* Content */}
        <div className="bg-white flex flex-grow rounded-2xl border-solid border border-[#E3E3E8] p-8">
          {currentContent === PROFILE_CONTENT && <ProfilePlatformContent />}
          {currentContent === SECURITY_CONTENT && null}
          {currentContent === CONTACTS_CONTENT && null}
        </div>
      </div>
    </div>
  )
}

export default ProfilePlatformPage
