import { combineReducers } from '@reduxjs/toolkit'
import { adminReducer, userReducer } from './auth'
import { adminCompanyReducer } from './admin/adminCompany'

const rootReducer = combineReducers({
  user: userReducer,
  admin: adminReducer,
  adminCompany: adminCompanyReducer
})

export default rootReducer
