import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  access_token: null,
  role: null,
  profile: {},
  isFirstLogin: true
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setProfile(state, action) {
      state.profile = action.payload
    },
    loginPlatform(state, action) {
      state.access_token = action.payload.access_token
      state.role = action.payload.role
      state.profile = action.payload.profile
    },
    setIsFirstLogin(state, action) {
      state.isFirstLogin = action.payload.isFirstLogin
    },
    logoutPlatform(state) {
      Object.assign(state, initialState)
    }
  }
})

export const userActions = userSlice.actions

export const userReducer = userSlice.reducer
