import React from 'react'
import NoteCard from 'components/atoms/user/cases/NoteCard'
import NotePanel from 'components/atoms/user/cases/NotePanel'

const ListNotes = ({ value, data, height }) => {
    return (
        <div className='w-full overflow-y-scroll py-2' style={{ height: height }}>
            <NotePanel
                value={value}
                index={0}
            >
                {data.map((note, idx) => (
                    <NoteCard
                        key={`note-${idx}`}
                        {...note}
                    />
                ))}
            </NotePanel>
            <NotePanel
                value={value}
                index={1}
            >
                {data.map((note, idx) => (
                    <NoteCard
                        key={`note-${idx}`}
                        {...note}
                    />
                ))}
            </NotePanel>
        </div>
    )
}

export default ListNotes