import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'
import { updateCompany } from 'api'
import CustomButton from 'components/atoms/CustomButton'
import CountrySelectFields from 'components/molecules/CountrySelectFields'
import PhoneNumberFields from 'components/molecules/PhoneNumberFields'
import TextFields from 'components/molecules/TextFields'
import ModalAdmin from 'components/molecules/admin/ModalAdmin'
import { COUNTRY_INPUT, GROUP_INPUT, PHONE_INPUT, TEXT_INPUT } from 'constant'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { showErrorMessage } from 'utils/showMessage'
import { z } from 'zod'

const scheme = z.object({
  // gptKey: z.string().min(1, { message: 'Secret Gpt Key is required' }),
  companyName: z.string().min(1, { message: 'Company name is required' }),
  email: z.string().email().min(1, { message: 'email is required' }),
  phoneNumber: z.string().min(1, { message: 'Telephone Number is required' }),
  SDIReceiverCode: z.string().min(7, { message: 'Min value 7' }).max(7, { message: 'Max value 7'}).optional().or(z.literal('')),
  VATNumber: z.string().min(8, { message: 'Min value 8' }).max(15, { message: 'Max value 15'}).optional().or(z.literal('')),
  TaxCode: z.string().min(6, { message: 'Min value 6' }).max(20, { message: 'Max value 20'}).optional().or(z.literal('')),
  PECAddress: z.string().email().optional().or(z.literal('')),
  country: z.object({
    id: z.number(),
    name: z.string()
  }).optional().or(z.undefined()).or(z.null()),
  address: z.string().optional().or(z.literal('')),
  municipality: z.string().min(3, { message: 'Min value 3' }).max(50, { message: 'Max value 50' }).optional().or(z.literal('')),
  zipCode: z.string().min(4, { message: 'Min value 4' }).max(19, { message: 'Max value 19' }).optional().or(z.literal('')),
  province: z.string().optional().optional().or(z.literal('')),
})

const inputFieldList = [
  {
    type: TEXT_INPUT,
    label: 'Company name',
    name: 'companyName'
  },
  {
    type: TEXT_INPUT,
    label: 'Email',
    name: 'email'
  },
  {
    type: PHONE_INPUT,
    label: 'Telephone Number',
    name: 'phoneNumber'
  },
  {
    type: TEXT_INPUT,
    label: 'SDI Receiver Code',
    name: 'SDIReceiverCode'
  },
  {
    type: TEXT_INPUT,
    label: 'VAT Number',
    name: 'VATNumber'
  },
  {
    type: TEXT_INPUT,
    label: 'Tax Code',
    name: 'TaxCode'
  },
  {
    type: TEXT_INPUT,
    label: 'PEC ADDRESS',
    name: 'PECAddress'
  },
  {
    type: COUNTRY_INPUT,
    label: 'Country',
    name: 'country'
  },
  {
    type: TEXT_INPUT,
    label: 'Address',
    name: 'address'
  },
  {
    type: TEXT_INPUT,
    label: 'Municipality',
    name: 'municipality'
  },
  {
    type: GROUP_INPUT,
    inputs: [
      {
        type: TEXT_INPUT,
        label: 'ZIP CODE',
        name: 'zipCode'
      },
      {
        type: TEXT_INPUT,
        label: 'Province',
        name: 'province'
      }
    ]
  }
]

const EditCompanyModal = ({ show, onClose, onSubmit, data, countries, refetch }) => {
  const token = useSelector(state => state.admin.access_token);
  const {
    handleSubmit,
    // trigger,
    // watch,
    reset,
    // setError,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      gpt_key: data?.gpt_key,
      companyName: data?.name ?? '',
      email: data?.email ?? '',
      phoneNumber: data?.phone ?? '',
      SDIReceiverCode: data?.sdi_receiver_code ?? undefined,
      VATNumber: data?.vat_number ?? undefined,
      TaxCode: data?.tax_code ?? undefined,
      PECAddress: data?.pec_address ?? undefined,
      country: countries?.data?.resData?.data?.[countries?.data?.resData?.data?.findIndex(item => data?.country_id === item?.id)] ?? undefined,
      address: data?.address ?? undefined,
      municipality: data?.municipality ?? undefined,
      zipCode: data?.zip_code ?? undefined,
      province: data?.province ?? undefined
    },
    resolver: zodResolver(scheme)
  })
  
  const processForm = async (data) => {
    await onEditCompany(data)
  }

  const onEditCompany = async (newData) => {
    try {
      let res
      let body = {
        gpt_key: data?.gpt_key,
        name: newData?.companyName,
        email: newData?.email,
        phone: newData?.phoneNumber.split(" ").join(""),
        address: newData?.address,
        municipality: newData?.municipality,
        pec_address: newData?.PECAddress,
        province: newData?.province,
        sdi_receiver_code: newData?.SDIReceiverCode,
        tax_code: newData?.TaxCode,
        vat_number: newData?.VATNumber,
        zip_code: newData?.zipCode,
        country_id: newData?.country?.id,
        // users: [],
      }

      let newBody = Object.fromEntries(Object.entries(body).filter(([, v]) => v != undefined && v != ""));

      res = await updateCompany(token, data?.id, newBody)

      if (res?.resStatus === 200 || res?.resStatus === 201) {
        refetch();
        onSubmit();
        onCloseModal();
      } else {
        showErrorMessage('Edit Failed!')
      }
    } catch (error) {
      showErrorMessage('Edit Failed!')
    }
  }

  function onCloseModal() {
    reset()
    onClose()
  }

  function renderForm(type, input, key) {
    switch (type) {
      case TEXT_INPUT:
        return <TextFields key={key} control={control} theme="admin" {...input} errors={errors} />
      case PHONE_INPUT:
        return <PhoneNumberFields key={key} control={control} theme="admin" {...input} errors={errors} />
      case COUNTRY_INPUT:
        return <CountrySelectFields
          key={key}
          countries={countries?.data?.resData?.data ?? []}
          fromBE={true}
          control={control}
          theme="admin"
          label={'Country'}
          errors={errors}
          name={'country'}
        />
      default:
        return false
    }
  }

  return (
    <ModalAdmin width={448} show={show} onClose={onCloseModal} title="Edit company info">
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(processForm)}
        className="flex flex-col justify-between min-h-[466px]">
        {/* Form Content */}
        <div className="flex flex-col gap-6 w-full">
          {inputFieldList.map(({ type, ...input }, index) => {
            if (type === GROUP_INPUT)
              return (
                <div key={`key-input-edit-${index}-div`} className="flex gap-4">
                  {input.inputs.map(({ type, ...input }) => renderForm(type, input))}
                </div>
              )
            return renderForm(type, input, `key-input-edit-${index}`)
          })}
        </div>
        {/* Button Wrapper */}
        <div className="flex gap-2 justify-end items-center pt-10">
          <CustomButton buttonColor="secondary" onClick={onCloseModal}>
            Cancel
          </CustomButton>
          <CustomButton buttonType='submit' onClick={handleSubmit(processForm)}>Save</CustomButton>
        </div>
      </Box>
    </ModalAdmin>
  )
}

export default EditCompanyModal
