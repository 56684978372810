import React from 'react'
import { ChatBubbleOutline, InsertDriveFileOutlined, StickyNote2Outlined } from '@mui/icons-material'
import { Typography } from 'antd'

const NoteCard = ({ note, type, typeName, createdAt }) => {
    return (
        <div className='w-full bg-[#EDEDEF] p-4 rounded-lg mb-3'>
            <Typography.Text className='mb-4 text-black block'>{note}</Typography.Text>
            <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                    {type === "chat" && (
                        <ChatBubbleOutline fontSize='small' className='text-[#828282] mr-1' />
                    )}
                    {type === "document" && (
                        <InsertDriveFileOutlined fontSize='small' className='text-[#828282] mr-1' />
                    )}
                    {type === "insight" && (
                        <StickyNote2Outlined fontSize='small' className='text-[#828282] mr-1' />
                    )}
                    <Typography.Text className='text-[#828282] text-[12px]'>{typeName}</Typography.Text>
                </div>
                <Typography.Text className='text-[#828282] text-[12px]'>{createdAt}</Typography.Text>
            </div>
        </div>
    )
}

export default NoteCard