import { Select } from 'antd'
import ErrorMessage from 'components/atoms/ErrorMessage'
import React from 'react'
import { Controller } from 'react-hook-form'

const SelectFields = ({
  control,
  label,
  labelClassName,
  name,
  options = [],
  fieldNames,
  receivedSelectedObject,
  errors,
  className,
  isRequired = false,
  ...selectProps
}) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <Controller
        name={name}
        control={control}
        required={isRequired}
        render={({ field }) => (
          <>
            <span className={`${errors?.[name] ? '!text-red-500' : ''} } ${labelClassName}`}>
              {label}
            </span>
            <Select
              {...field}
              fieldNames={fieldNames}
              className={`bg-[#FFFFFF0F] !text-[#999999] ${className}`}
              popupClassName="!bg-[#292929] !text-[#999999]"
              optionFilterProp="label"
              onChange={(value, option) => {
                receivedSelectedObject ? field.onChange(option) : field.onChange(value)
              }}
              options={options}
              {...selectProps}></Select>
          </>
        )}
      />
      {errors[name] ? <ErrorMessage errorMessage={errors[name].message} /> : null}
    </div>
  )
}
export default SelectFields
