import ModalAdmin from 'components/molecules/admin/ModalAdmin'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
// import { addCompanyFormSchema } from 'lib/schema'
import { Box } from '@mui/material'
import TextFields from 'components/molecules/TextFields'
import PhoneNumberFields from 'components/molecules/PhoneNumberFields'
import CustomButton from 'components/atoms/CustomButton'
// import AddIcon from '@mui/icons-material/Add'
// import MultipleSelectFields from 'components/molecules/MultipleSelectFields'
// import { staticUsersOptions } from 'data/users'
import CountrySelectFields from 'components/molecules/CountrySelectFields'
import {
  // ArrowLeftOutlined,
  ChevronLeft 
} from '@mui/icons-material'
import { addCompany, getCompanySearchByName, getCountryList, validateGptKey } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { z } from 'zod'
import {
  showErrorMessage,
  // showSuccessMessage
} from 'utils/showMessage'
import { adminCompanyActions } from 'store/reducers/admin/adminCompany'
import { useQuery } from 'react-query'
// import SelectFields from 'components/molecules/SelectFields'

const steps = [
  {
    id: '1',
    name: 'Enter the secret Gpt key',
    optional: false,
    fields: ['gptKey'],
    schema: z.object({
      gptKey: z.string().min(1, { message: 'Secret Gpt Key is required' }),
    }),
    defaultValues: {
      gptKey: '',
    }
  },
  {
    id: '2',
    name: 'Add company',
    optional: false,
    fields: ['companyName', 'email', 'phoneNumber'],
    schema: z.object({
      companyName: z.string().min(1, { message: 'Company name is required' }),
      email: z.string().email().min(1, { message: 'email is required' }),
      phoneNumber: z.string().min(1, { message: 'Telephone Number is required' }),
    }),
  },
  // {
  //   id: '3',
  //   name: 'Pair users',
  //   optional: true,
  //   fields: ['users']
  // },
  {
    // id: '4',
    id: '3',
    name: 'Company registry information',
    optional: true,
    fields: [
      'SDIReceiverCode',
      'VATNumber',
      'TaxCode',
      'PECAddress',
      'country',
      'address',
      'municipality',
      'zipCode',
      'province'
    ],
    schema: z.object({
      SDIReceiverCode: z.string().min(7, { message: 'Min value 7' }).max(7, { message: 'Max value 7'}).optional().or(z.literal('')),
      VATNumber: z.string().min(8, { message: 'Min value 8' }).max(15, { message: 'Max value 15'}).optional().or(z.literal('')),
      TaxCode: z.string().min(6, { message: 'Min value 6' }).max(20, { message: 'Max value 20'}).optional().or(z.literal('')),
      PECAddress: z.string().email().optional().or(z.literal('')),
      country: z.object({
        id: z.number(),
        name: z.string()
      }).optional().or(z.undefined()).or(z.null()),
      address: z.string().optional().or(z.literal('')),
      municipality: z.string().min(3, { message: 'Min value 3' }).max(50, { message: 'Max value 50' }).optional().or(z.literal('')),
      zipCode: z.string().min(4, { message: 'Min value 4' }).max(19, { message: 'Max value 19' }).optional().or(z.literal('')),
      province: z.string().optional().optional().or(z.literal('')),
    }),
  }
]

const AddCompanyModal = ({ show, onClose, onSubmit }) => {
  const [currentStep, setCurrentStep] = useState({
    step: 0,
    needValidate: false
  });
  const token = useSelector(state => state.admin.access_token);
  const company = useSelector(state => state.adminCompany);
  const dispatch = useDispatch()
  const {
    handleSubmit,
    // watch,
    reset,
    trigger,
    // setError,
    getFieldState,
    // getValues,
    // setValue,
    control,
    formState: {
      errors,
      isValid
    }
  } = useForm({
    resolver: zodResolver(steps[currentStep?.step].schema),
    defaultValues: {
      gptKey: '',
      companyName: '',
      email: '',
      phoneNumber: '',
      users: [],
      SDIReceiverCode: undefined,
      VATNumber: undefined,
      TaxCode: undefined,
      PECAddress: undefined,
      country: undefined,
      address: undefined,
      municipality: undefined,
      zipCode: undefined,
      province: undefined
    },
  })

  const { data } = useQuery({
    queryKey: [
      'countries-list',
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getCountryList(token)
      return res
    },
  })

  function closeModal() {
    reset()
    onClose()
    setCurrentStep({
      step: 0,
      needValidate: false
    })
  }

  const onAddCompany = async (data) => {
    try {
      let res
      let body = {
        ...company,
        address: data?.address,
        municipality: data?.municipality,
        pec_address: data?.PECAddress,
        province: data?.province,
        sdi_receiver_code: data?.SDIReceiverCode,
        tax_code: data?.TaxCode,
        vat_number: data?.VATNumber,
        zip_code: data?.zipCode,
        country_id: data?.country?.id,
        phone: company?.phone?.split(" ").join("")
        // users: [],
      }

      let newBody = Object.fromEntries(Object.entries(body).filter(([, v]) => v != undefined && v != ""));

      res = await addCompany(token, newBody)

      if (res?.resStatus === 200 || res?.resStatus === 201) {
        onSubmit();
        dispatch(adminCompanyActions.resetAll());
        closeModal();
      } else {
        showErrorMessage('Company name is exists!')
      }
    } catch (error) {
      showErrorMessage('Company name is exists!')
    }
  }

  const onValidateGptKey = async (data) => {
    try {
      let res
      let body = {
        gpt_key: data?.gptKey
      }

      res = await validateGptKey(token, body)

      if (res?.resStatus === 200 || res?.resStatus === 201) {
        // showSuccessMessage('secretKeyEdited')
        // refetch()
        dispatch(adminCompanyActions.setStep1({
          gpt_key: data?.gptKey
        }))
        setCurrentStep((curr) => {
          return {
            step: curr.step + 1,
            needValidate: false
          }
        })
      } else {
        showErrorMessage('The Secret key is not valid')
      }
    } catch (error) {
      showErrorMessage('The Secret key is not valid')
    }
  }

  const onValidateCompanyName = async (data) => {
    try {
      let res
      res = await getCompanySearchByName(token, data?.companyName)

      if (res?.resStatus === 200 || res?.resStatus === 201) {
        showErrorMessage('Company name is exists!')
      } else {
        dispatch(adminCompanyActions.setStep2({
          name: data?.companyName,
          email: data?.email,
          phone: data?.phoneNumber
        }))
        setCurrentStep((curr) => {
          return {
            step: curr.step + 1,
            needValidate: false
          }
        })
      }
    } catch (error) {
      if (error.response.status === 404) {
        dispatch(adminCompanyActions.setStep2({
          name: data?.companyName,
          email: data?.email,
          phone: data?.phoneNumber
        }))
        setCurrentStep((curr) => {
          return {
            step: curr.step + 1,
            needValidate: false
          }
        })
      } else {
        showErrorMessage('Internal server error')
      }
    }
  }

  const next = async () => {
    const fields = steps[currentStep?.step].fields
    const output = await trigger(fields, { shouldFocus: true })

    if (!output) return

    if (currentStep?.step < steps.length) {
      if (currentStep?.step === steps.length - 1) {
        // if (watchCompany && !watchRole) {
        //   return setError('role', { message: 'Role is required' })
        // }
        return await handleSubmit(onAddCompany)()
      }

      if (currentStep?.step === 0) {
        return await handleSubmit(onValidateGptKey)()
      }
      if (currentStep?.step === 1) {
        return await handleSubmit(onValidateCompanyName)()
      }
      // setCurrentStep((step) => step + 1)
      // reset({ ...getValues() }, { keepValues: true })
    }
  }

  const prev = () => {
    if (currentStep?.step > 0) {
      setCurrentStep((curr) => {
        return {
          step: curr.step - 1,
          needValidate: true
        }
      })
    }
  }

  useEffect(() => {
    if(currentStep?.needValidate) {
      const fields = steps[currentStep?.step].fields;
      trigger(fields, { shouldFocus: true })
    }
  }, [currentStep])

  return (
    <ModalAdmin
      width={448}
      show={show}
      onClose={closeModal}
      title={
        <div className="flex flex-col">
          <span>{steps[currentStep?.step].name}</span>
          <div className="!font-thin !text-xs text-[#FFFFFF5C] flex justify-start items-center ">
            {currentStep?.step > 0 && (
              <div className="flex justify-start items-center -ml-2 cursor-pointer" onClick={prev}>
                <ChevronLeft />
              </div>
            )}{' '}
            STEP {currentStep?.step + 1} OF {steps.length} {steps[currentStep?.step].optional && '(optional)'}
          </div>
        </div>
      }>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(onAddCompany)}
        className="flex flex-col justify-between min-h-[466px]">
        {currentStep?.step === 0 && (
          <div className="flex flex-col gap-6 w-full">
            <TextFields
              control={control}
              theme="admin"
              label={'Secret Gpt key'}
              errors={errors}
              name={'gptKey'}
              helperText={
                <span className="text-[#FFFFFF7A] font-manrope">
                  Do you not have a secret key?{' '}
                  <span className="text-white cursor-pointer hover:text-[#FFFFFF7A]">
                    Contact us
                  </span>
                </span>
              }
            />
          </div>
        )}
        {currentStep?.step === 1 && (
          <div className="flex flex-col gap-6 w-full">
            <TextFields
              control={control}
              theme="admin"
              label={'Company name'}
              errors={errors}
              name={'companyName'}
            />
            <TextFields
              control={control}
              theme="admin"
              label={'Email'}
              errors={errors}
              name={'email'}
            />
            <PhoneNumberFields
              control={control}
              theme="admin"
              label={'Telephone Number'}
              placeholder={'input phone number'}
              errors={errors}
              name={'phoneNumber'}
            />
          </div>
        )}
        {/* {currentStep?.step === 2 && (
          <div className="flex flex-col gap-6 w-full">
            <MultipleSelectFields
              labelClassName={'!text-white'}
              control={control}
              isShowTotalSelect
              totalSelectedItems={watchUsers?.length}
              label={'Users'}
              state={watchUsers}
              errors={errors}
              name={'users'}
              options={staticUsersOptions}
            />
            <span className="text-[#FFFFFF5C] font-manrope">Or</span>
            <div className="flex w-fit">
              <CustomButton buttonColor="secondary">
                <div className="flex gap-3">
                  <AddIcon sx={{ width: '20px', height: '20px' }} /> <span>Add New</span>
                </div>
              </CustomButton>
            </div>
          </div>
        )} */}
        {currentStep?.step === 2 && (
          <div className="flex flex-col gap-6 w-full">
            <TextFields
              control={control}
              theme="admin"
              label={'SDI Receiver Code'}
              errors={errors}
              name={'SDIReceiverCode'}
            />
            <TextFields
              control={control}
              theme="admin"
              label={'VAT Number'}
              errors={errors}
              name={'VATNumber'}
            />
            <TextFields
              control={control}
              theme="admin"
              label={'Tax Code'}
              errors={errors}
              name={'TaxCode'}
            />
            <TextFields
              control={control}
              theme="admin"
              label={'PEC Address'}
              errors={errors}
              name={'PECAddress'}
            />
            <CountrySelectFields
              countries={data?.resData?.data ?? []}
              fromBE={true}
              control={control}
              theme="admin"
              label={'Country'}
              errors={errors}
              name={'country'}
            />
            <TextFields
              control={control}
              theme="admin"
              label={'Address'}
              errors={errors}
              name={'address'}
            />
            <TextFields
              control={control}
              theme="admin"
              label={'Municipality'}
              errors={errors}
              name={'municipality'}
            />
            <div className="flex gap-4">
              <TextFields
                type="number"
                control={control}
                theme="admin"
                label={'ZIP CODE'}
                errors={errors}
                name={'zipCode'}
              />
              <TextFields
                control={control}
                theme="admin"
                label={'Province'}
                errors={errors}
                name={'province'}
              />
            </div>
          </div>
        )}
        <div className="flex gap-2 justify-end items-center pt-10">
          <CustomButton buttonColor="secondary" onClick={closeModal}>
            Cancel
          </CustomButton>
          <CustomButton
            onClick={next}
            disabled={
              (!getFieldState('gptKey').isDirty && currentStep?.step == 0) ||
              (!isValid && currentStep.step == 1) ||
              (!isValid && currentStep.step == 2)
            }>
            {currentStep?.step < steps.length - 1 ? 'Continue' : 'Add Company'}
          </CustomButton>
        </div>
      </Box>
    </ModalAdmin>
  )
}

export default AddCompanyModal
