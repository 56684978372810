import { TablePagination } from '@mui/material'
import CaseCard from 'components/molecules/user/cases/CaseCard'
import React from 'react'

const GridCases = ({
  data = [],
  onViewDetail = () => {},
  onEdit = () => {},
  onExport = () => {},
  onDelete = () => {}
}) => {
  return (
    <div className="h-full w-full flex flex-col justify-between">
      <div className="h-[calc(100vh-170px)] w-full overflow-y-auto pb-9 pr-6 grid gap-9 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 min-[2200px]:grid-cols-6 min-[2400px]:grid-cols-7">
        {data?.map((caseItem, index) => (
          <CaseCard
            key={index}
            onDeleteCase={onDelete}
            onEdit={onEdit}
            onExport={onExport}
            onViewDetail={onViewDetail}
            titleCase={caseItem.caseName}
            statusCase={caseItem.status}
            date={caseItem.updatedAt}
            totalChat={caseItem.totalChat}
            totalDocs={caseItem.totalDocument}
            totalNotes={caseItem.totalNote}
          />
        ))}
      </div>
      <div className="pr-6">
        {/* Count == TOTAL_ITEMS, rowsPerPage === ITEMS_PER_PAGE */}
        <TablePagination
          component="div"
          count={30}
          rowsPerPageOptions={[30, 50, 100]}
          page={0}
          onPageChange={() => {}}
          rowsPerPage={30}
          onRowsPerPageChange={() => {}}
        />
      </div>
    </div>
  )
}

export default GridCases
