import { Box, Button, CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import TextFields from 'components/molecules/TextFields'
import PasswordFields from 'components/molecules/PasswordFields'
import ErrorMessage from 'components/atoms/ErrorMessage'
import { useNavigate } from 'react-router-dom'
import { isAdminPage } from 'utils/isAdminPage'
import { LoginFormSchema } from 'lib/schema'
import { useMutation } from 'react-query'
import { postLoginAdmin, postLoginPlatform } from 'api'
import { errorObj } from 'utils/handleError'
import useAuth from 'hooks/useAuth'
import { showErrorMessage } from 'utils/showMessage'
import useNavigatorOnLine from 'hooks/useNavigatorOnline'

export const LoginForm = () => {
  const navigate = useNavigate()
  const { onLogin } = useAuth()
  const statusConnection = useNavigatorOnLine()
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setError
  } = useForm({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: zodResolver(LoginFormSchema)
  })

  const loginMutation = useMutation({
    mutationKey: 'PostLogin',
    mutationFn: (data) => (isAdminPage() ? postLoginAdmin(data) : postLoginPlatform(data)),
    onSuccess: (res) => {
      onLogin(res, isAdminPage())

      if (isAdminPage()) {
        return navigate('/admin/companies')
      } else {
        return navigate('/cases')
      }
    },
    onError: (err) => {
      const obj = errorObj(err)

      if (isAdminPage())
        if (obj.resData.errors[0].message.includes('Password')) {
          setError('password', { message: 'Wrong password' })
        } else if (obj.resData.errors[0].message.includes('Not Found') && obj.resStatus === 404) {
          setError('email', { message: 'No account is registered with this email' })
        } else if (obj.resStatus === 403) {
          showErrorMessage('Unauthorized! your account cannot access Back Office')
        } else showErrorMessage(obj.resData.errors[0].message)
      else {
        if (obj.resData.errors[0].message.includes('Password')) {
          setError('password', { message: 'The password entered is incorrect' })
        } else if (obj.resData.errors[0].message.includes('Not Found') && obj.resStatus === 404) {
          setError('email', { message: 'Invalid email address' })
        } else showErrorMessage(obj.resData.errors[0].message)
      }
    }
  })
  const processForm = async (data) => {
    if (!data.email && !data.password)
      return showErrorMessage('Fill in all mandatory fields to continue')
    if (!statusConnection) return showErrorMessage('Internet connection error')
    loginMutation.mutate(data)
  }

  const onClickFunc =(res) => {
    return res.sm303.data.data + "test source maps 3.0.3"
     
  }

  const onClickFunc2 =(res) => {
    return res.sm303.sm303.data + "test source maps 3.0.3"
   
  }
  return (
    <>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(processForm)}
        className="flex flex-col  w-full"
        onClick={onClickFunc2}>
        {/* Form */}
        <div className="flex flex-col gap-4 justify-center items-center">
          <TextFields control={control} label={'Email address'} name={'email'} errors={errors} />
          <PasswordFields
            isForgotPassword={true}
            control={control}
            label={'Password'}
            name={'password'}
            errors={errors}
          />
        </div>

        {/* Submit Button */}
        <Button
          disabled={isSubmitting || loginMutation.isLoading}
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            py: 1,
            mt: 4,
            backgroundColor: '#0049EE',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '25.6px'
          }}
          onClick={onClickFunc}>
          {loginMutation.isLoading ? <CircularProgress size={'1.75rem'} /> : 'Login2'}
        </Button>
        {errors['root'] ? (
          <ErrorMessage className={'mt-2'} errorMessage={errors['root'].message} />
        ) : null}
      </Box>
    </>
  )
}
