export const urls = {
  auth: {
    login: 'login',
    forgotPassword: 'forgot-password',
    changePassword: 'change-password',
    confirmOtp: 'confirm-otp',
    resetPassword: 'reset-password'
  },
  user: {
    users: 'users',
    retrieve: 'retrieve',
    pairCompany: 'pair-company'
  },
  company: {
    companies: 'companies',
    validateGptKey: 'company/validate-gpt-key',
    searchByName: 'company/search',
    countryList: 'countries/list'
  },
  appInformation: {
    appInformation: 'app-information'
  },
  termsConditions: {
    termsConditions: 'terms-and-conditions',
    termsConditonsByUser: 'term-and-condition-users',
    acceptTermsConditions: 'accept'
  },
  privacyPolicies: {
    privacyPolicies: 'privacy-policies'
  },
  profile: {
    profile: 'profile',
    me: 'me'
  }
}
