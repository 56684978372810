import { Typography } from 'antd'
import { apiDelete } from 'api'
import CustomButton from 'components/atoms/CustomButton'
import ModalAdmin from 'components/molecules/admin/ModalAdmin'
import React from 'react'
import { useSelector } from 'react-redux'
import { showErrorMessage } from 'utils/showMessage'

const DeleteProfileCompanyModal = ({ show, onClose, onDeleteProfile, data, refetch }) => {

  const token = useSelector(state => state.admin.access_token);

  const onDeleteCompany = async () => {
    try {
      let res
      res = await apiDelete({ url: `companies/${data?.id}`, token })

      if (res?.resStatus === 200 || res?.resStatus === 201) {
        refetch();
        onDeleteProfile()
        onClose();
      } else {
        showErrorMessage('Delete Failed!')
      }
    } catch (error) {
      showErrorMessage('Delete Failed!')
    }
  }

  return (
    <ModalAdmin title={'Delete Profile'} onClose={onClose} show={show}>
      <div className="flex flex-col gap-2">
        <Typography.Paragraph className="!text-[#FFFFFFCC] !font-manrope !text-lg">
          Are you sure you want to delete this {"Company's"} profile? You will have up to 30 days to
          retrieve the account, after which user will lose access to the dashboard
        </Typography.Paragraph>

        <div className="flex gap-2 justify-end items-center">
          <CustomButton buttonColor="secondary" onClick={onClose}>
            Cancel
          </CustomButton>
          <CustomButton
            buttonColor="danger"
            onClick={onDeleteCompany}>
            Delete
          </CustomButton>
        </div>
      </div>
    </ModalAdmin>
  )
}

export default DeleteProfileCompanyModal
