import { apiGet } from 'api/api-get'
import { apiPost } from 'api/api-post'
import { urls } from 'api/api-url'

export const getTermsConditions = (token) => {
  return apiGet({ url: urls.termsConditions.termsConditions, token: token })
}

export const updateTermsConditions = (token, data) => {
  return apiPost({ url: urls.termsConditions.termsConditions, token: token, data: data })
}
