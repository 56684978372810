import ChangePasswordForm from 'components/organisms/auth/ChangePasswordForm'
import OTPForm from 'components/organisms/auth/OTPForm'
import ResetPasswordForm from 'components/organisms/auth/ResetPasswordForm'
import React, { useState } from 'react'
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import { isAdminPage } from 'utils/isAdminPage'
import LoginFormTemplate from 'components/templates/LoginFormTemplate'

const OTP = 'OTP'
const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
const RECOVERY_LINK = 'RECOVERY_LINK'

const ResetPasswordPage = () => {
  const { platformData, adminData } = useAuth()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') || undefined
  const location = useLocation()
  const navigate = useNavigate()
  const [currentContent, setCurrentContent] = useState(token ? OTP : RECOVERY_LINK)

  // HANDLE IF USER LOGGEDIN STILL ACCESS RESET PASSWORD PAGE
  if (isAdminPage()) {
    if (adminData.access_token) {
      return <Navigate to="/admin/companies" state={{ from: location }} replace />
    }
  } else if (platformData.access_token)
    return <Navigate to="/" state={{ from: location }} replace />

  function navigateContent(value) {
    setCurrentContent(value)
  }
  function handleNavLogin() {
    isAdminPage()
      ? navigate('/admin/login', { replace: true })
      : navigate('/login', { replace: true })
  }

  return (
    <LoginFormTemplate>
      <div className="max-w-[360px] h-full flex flex-col justify-center items-center gap-10">
        {/* Form */}
        {!token ? (
          <ResetPasswordForm />
        ) : token && currentContent !== CHANGE_PASSWORD ? (
          <OTPForm onNavigate={navigateContent.bind(this, CHANGE_PASSWORD)} />
        ) : currentContent === CHANGE_PASSWORD ? (
          <ChangePasswordForm onNavigate={handleNavLogin} />
        ) : null}
      </div>
    </LoginFormTemplate>
  )
}

export default ResetPasswordPage
