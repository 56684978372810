import React from 'react'

const IcRegulation = ({ isActive = true }) => (
  <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="path-1-outside-1_3768_63877"
      maskUnits="userSpaceOnUse"
      x="0.5"
      y="2.43359"
      width="27"
      height="19"
      fill="black">
      <rect fill="white" x="0.5" y="2.43359" width="27" height="19" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 3.43359C1.94772 3.43359 1.5 3.88131 1.5 4.43359V20.301H13.9974V6.43359C13.9974 4.77674 12.6542 3.43359 10.9974 3.43359H2.5ZM17.0027 3.43361C15.3458 3.43361 14.0027 4.77675 14.0027 6.43361V20.301H26.5V4.43361C26.5 3.88132 26.0523 3.43361 25.5 3.43361H17.0027Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 3.43359C1.94772 3.43359 1.5 3.88131 1.5 4.43359V20.301H13.9974V6.43359C13.9974 4.77674 12.6542 3.43359 10.9974 3.43359H2.5ZM17.0027 3.43361C15.3458 3.43361 14.0027 4.77675 14.0027 6.43361V20.301H26.5V4.43361C26.5 3.88132 26.0523 3.43361 25.5 3.43361H17.0027Z"
      fill="white"
    />
    <path
      d="M1.5 20.301H0.5V21.301H1.5V20.301ZM13.9974 20.301V21.301H14.9974V20.301H13.9974ZM14.0027 20.301H13.0027V21.301H14.0027V20.301ZM26.5 20.301V21.301H27.5V20.301H26.5ZM2.5 4.43359L2.5 4.43359V2.43359C1.39543 2.43359 0.5 3.32902 0.5 4.43359H2.5ZM2.5 20.301V4.43359H0.5V20.301H2.5ZM13.9974 19.301H1.5V21.301H13.9974V19.301ZM12.9974 6.43359V20.301H14.9974V6.43359H12.9974ZM10.9974 4.43359C12.102 4.43359 12.9974 5.32903 12.9974 6.43359H14.9974C14.9974 4.22445 13.2065 2.43359 10.9974 2.43359V4.43359ZM2.5 4.43359H10.9974V2.43359H2.5V4.43359ZM15.0027 6.43361C15.0027 5.32904 15.8981 4.43361 17.0027 4.43361V2.43361C14.7935 2.43361 13.0027 4.22447 13.0027 6.43361H15.0027ZM15.0027 20.301V6.43361H13.0027V20.301H15.0027ZM26.5 19.301H14.0027V21.301H26.5V19.301ZM25.5 4.43361V20.301H27.5V4.43361H25.5ZM25.5 4.43361H27.5C27.5 3.32904 26.6046 2.43361 25.5 2.43361V4.43361ZM17.0027 4.43361H25.5V2.43361H17.0027V4.43361Z"
      fill={isActive ? '#595961' : '#C6C7CF'}
      mask="url(#path-1-outside-1_3768_63877)"
    />
    <mask id="path-3-inside-2_3768_63877" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00204 0.769531H4.38867V21.2303H4.3887V15.6498H9.00207C11.7576 15.6498 13.9925 17.8789 14.002 20.6322V5.76953C14.002 3.00811 11.7635 0.769531 9.00204 0.769531Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00204 0.769531H4.38867V21.2303H4.3887V15.6498H9.00207C11.7576 15.6498 13.9925 17.8789 14.002 20.6322V5.76953C14.002 3.00811 11.7635 0.769531 9.00204 0.769531Z"
      fill="white"
    />
    <path
      d="M4.38867 0.769531V-0.230469H3.38867V0.769531H4.38867ZM4.38867 21.2303H3.38867V22.2303H4.38867V21.2303ZM4.3887 21.2303V22.2303H5.3887V21.2303H4.3887ZM4.3887 15.6498V14.6498H3.3887V15.6498H4.3887ZM14.002 20.6322L13.002 20.6356L15.002 20.6322H14.002ZM4.38867 1.76953H9.00204V-0.230469H4.38867V1.76953ZM5.38867 21.2303V0.769531H3.38867V21.2303H5.38867ZM4.3887 20.2303H4.38867V22.2303H4.3887V20.2303ZM5.3887 21.2303V15.6498H3.3887V21.2303H5.3887ZM4.3887 16.6498H9.00207V14.6498H4.3887V16.6498ZM9.00207 16.6498C11.2065 16.6498 12.9944 18.4331 13.002 20.6356L15.002 20.6287C14.9906 17.3247 12.3087 14.6498 9.00207 14.6498V16.6498ZM13.002 5.76953V20.6322H15.002V5.76953H13.002ZM9.00204 1.76953C11.2112 1.76953 13.002 3.56039 13.002 5.76953H15.002C15.002 2.45582 12.3158 -0.230469 9.00204 -0.230469V1.76953Z"
      fill={isActive ? '#595961' : '#C6C7CF'}
      mask="url(#path-3-inside-2_3768_63877)"
    />
    <mask
      id="path-5-outside-3_3768_63877"
      maskUnits="userSpaceOnUse"
      x="0.5"
      y="16.6367"
      width="27"
      height="5"
      fill="black">
      <rect fill="white" x="0.5" y="16.6367" width="27" height="5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6659 17.6367C15.195 17.6367 14.0027 18.8291 14.0027 20.3H26.5V17.6367H16.6659ZM11.3341 17.6367H1.5V20.3H13.9974C13.9974 18.8291 12.805 17.6367 11.3341 17.6367Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6659 17.6367C15.195 17.6367 14.0027 18.8291 14.0027 20.3H26.5V17.6367H16.6659ZM11.3341 17.6367H1.5V20.3H13.9974C13.9974 18.8291 12.805 17.6367 11.3341 17.6367Z"
      fill="#D9DBE3"
    />
    <path
      d="M14.0027 20.3H13.0027V21.3H14.0027V20.3ZM26.5 20.3V21.3H27.5V20.3H26.5ZM26.5 17.6367H27.5V16.6367H26.5V17.6367ZM1.5 17.6367V16.6367H0.5V17.6367H1.5ZM1.5 20.3H0.5V21.3H1.5V20.3ZM13.9974 20.3V21.3H14.9974V20.3H13.9974ZM15.0027 20.3C15.0027 19.3814 15.7473 18.6367 16.6659 18.6367V16.6367C14.6428 16.6367 13.0027 18.2768 13.0027 20.3H15.0027ZM26.5 19.3H14.0027V21.3H26.5V19.3ZM25.5 17.6367V20.3H27.5V17.6367H25.5ZM16.6659 18.6367H26.5V16.6367H16.6659V18.6367ZM1.5 18.6367H11.3341V16.6367H1.5V18.6367ZM2.5 20.3V17.6367H0.5V20.3H2.5ZM13.9974 19.3H1.5V21.3H13.9974V19.3ZM11.3341 18.6367C12.2527 18.6367 12.9974 19.3814 12.9974 20.3H14.9974C14.9974 18.2768 13.3573 16.6367 11.3341 16.6367V18.6367Z"
      fill={isActive ? '#595961' : '#C6C7CF'}
      mask="url(#path-5-outside-3_3768_63877)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.791 3.43359H22.6364V9.64791H18.791V3.43359ZM18.796 11.4237L18.796 9.64822H20.7187L18.796 11.4237ZM20.7187 9.64822L22.6414 11.4237L22.6414 9.64822H20.7187Z"
      fill={isActive ? '#0049EE' : '#C6C7CF'}
    />
    <path
      d="M1.5 17.6367H11.3341C12.805 17.6367 13.9974 18.8291 13.9974 20.3H1.5V17.6367Z"
      fill="#D9DBE3"
    />
  </svg>
)

export default IcRegulation
