import axios from 'axios'
import * as Sentry from '@sentry/react'

import _, { upperCase } from 'lodash'
import { useDispatch } from 'react-redux'
import { isAdminPage } from 'utils/isAdminPage'
import { adminActions, userActions } from 'store/reducers/auth'

export const rootService = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
})
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    const dispatch = useDispatch()
    if (error.response.status === 401) {
      _.throttle(() => {
        return isAdminPage()
          ? dispatch(adminActions.logoutBackOffice())
          : dispatch(userActions.logoutPlatform())
      }, 3000)()
    }
    Sentry.withScope(function (scope) {
      scope.setTransactionName(`AXIOS ${upperCase(error.config.method)}`)
      // scope.setLevel('')
      Sentry.captureException(error)
    })
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)
