import ModalAdmin from 'components/molecules/admin/ModalAdmin'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { addUserFormSchema } from 'lib/schema'
import { Box } from '@mui/material'
import TextFields from 'components/molecules/TextFields'
import PhoneNumberFields from 'components/molecules/PhoneNumberFields'
import SelectFields from 'components/molecules/SelectFields'
import CustomButton from 'components/atoms/CustomButton'
import AddIcon from '@mui/icons-material/Add'
import { ChevronLeft } from '@mui/icons-material'
import { ROLE_ADMIN, ROLE_USER } from 'constant'
import { useMutation } from 'react-query'
import { postCreateUser } from 'api'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import useAuth from 'hooks/useAuth'
const steps = [
  {
    id: '1',
    name: 'Add User',
    optional: false,
    fields: ['name', 'surname', 'email', 'phoneNumber']
  },
  {
    id: '2',
    name: 'Pair Company',
    optional: false,
    fields: ['company', 'role']
  }
]
const AddUserModal = ({ show, onClose, navigateToAddCompany, companyList, refetchUserList, company = null }) => {
  const { getAccessToken } = useAuth()
  const [currentStep, setCurrentStep] = useState(0)
  const {
    handleSubmit,
    watch,
    reset,
    trigger,
    getFieldState,
    setError,
    control,
    formState: { errors, isValid }
  } = useForm({
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      phoneNumber: '',
      company: company?.id ?? '',
      role: ''
    },
    mode: 'onChange',
    resolver: zodResolver(addUserFormSchema)
  })

  const watchCompany = watch('company')

  const addUserMutation = useMutation({
    mutationKey: 'addUser',
    mutationFn: (userData) => postCreateUser(getAccessToken(), userData),
    onSuccess: () => {
      refetchUserList()
      showSuccessMessage('User added')
      closeModal()
    },
    onError: () => {
      showErrorMessage('The user could not be added')
    }
  })

  const processForm = (data) => {
    addUserMutation.mutate(data)
  }
  function closeModal() {
    reset()
    onClose()
    setCurrentStep(0)
  }

  const next = async () => {
    const fields = steps[currentStep].fields
    const output = await trigger(fields, { shouldFocus: true })

    if (!output) return

    if (currentStep < steps.length) {
      if (currentStep === steps.length - 1) {
        return await handleSubmit(processForm)()
      }
      setCurrentStep((step) => step + 1)
    }
  }

  const prev = () => {
    if (currentStep === 0) {
      return closeModal()
    }
    if (currentStep > 0) {
      setCurrentStep((step) => step - 1)
    }
  }

  return (
    <ModalAdmin
      width={448}
      show={show}
      onClose={closeModal}
      title={
        <div className="flex flex-col">
          <span>{steps[currentStep].name}</span>
          <div className="!font-thin !text-xs text-[#FFFFFF5C] flex justify-start items-center ">
            {currentStep > 0 && (
              <div className="flex justify-start items-center -ml-2 cursor-pointer" onClick={prev}>
                <ChevronLeft />
              </div>
            )}{' '}
            STEP {currentStep + 1} OF {steps.length} {steps[currentStep].optional && '(optional)'}
          </div>
        </div>
      }>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(processForm)}
        className="flex flex-col justify-between min-h-[466px]">
        {currentStep == 0 && (
          <div className="flex flex-col gap-6 w-full">
            <TextFields
              control={control}
              theme="admin"
              label={'Name'}
              errors={errors}
              name={'name'}
            />
            <TextFields
              control={control}
              theme="admin"
              label={'Surname'}
              errors={errors}
              name={'surname'}
            />
            <TextFields
              control={control}
              theme="admin"
              label={'Email'}
              errors={errors}
              name={'email'}
            />
            <PhoneNumberFields
              control={control}
              theme="admin"
              label={'Telephone Number (optional)'}
              placeholder={'input phone number'}
              errors={errors}
              name={'phoneNumber'}
            />
          </div>
        )}
        {currentStep == 1 && (
          <div className="flex flex-col gap-6 w-full">
            {/* Company Fields */}
            {company && <SelectFields
              className={'bg-[#E9E9E9] rounded-lg'}
              labelClassName={'!text-white'}
              control={control}
              placeholder={'Choose Company'}
              label={'Company'}
              errors={errors}
              name={'company'}
              disabled={true}
              options={[{ value: company?.id, label: company?.name }]}
            />}
            {!company && <SelectFields
              labelClassName={'!text-white'}
              control={control}
              placeholder={'Choose Company'}
              label={'Company'}
              errors={errors}
              name={'company'}
              options={companyList}
            />}
            {!company && <span className="text-[#FFFFFF5C] font-manrope">Or</span>}
            {!company && <div className="flex w-fit">
              <CustomButton onClick={navigateToAddCompany} buttonColor="secondary">
                <div className="flex gap-2 text-white hover:text-white">
                  <AddIcon sx={{ width: '20px', height: '20px' }} />
                  <span>Add New</span>
                </div>
              </CustomButton>
            </div>}
            {watchCompany ? (
              <SelectFields
                labelClassName={'!text-white'}
                control={control}
                placeholder={'Choose Role'}
                label={'Role'}
                errors={errors}
                name={'role'}
                options={[
                  {
                    label: 'Admin',
                    value: ROLE_ADMIN
                  },
                  {
                    label: 'User',
                    value: ROLE_USER
                  }
                ]}
              />
            ) : null}
          </div>
        )}
        <div className="flex gap-2 justify-end items-center pt-10">
          <CustomButton
            disabled={addUserMutation.isLoading}
            buttonColor="secondary"
            onClick={closeModal}>
            Cancel
          </CustomButton>
          <CustomButton
            isLoading={addUserMutation.isLoading}
            disabled={
              (!(
                getFieldState('name').isDirty &&
                getFieldState('surname').isDirty &&
                getFieldState('email').isDirty
              ) &&
                currentStep === 0) ||
              (!isValid && currentStep === 1) ||
              addUserMutation.isLoading
            }
            onClick={next}>
            {currentStep == 0 ? 'Continue' : 'Add User'}
          </CustomButton>
        </div>
      </Box>
    </ModalAdmin>
  )
}

export default AddUserModal
