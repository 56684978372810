import React, { useEffect, useRef, useState } from 'react'
import {
    ArrowForward,
    ChatBubbleOutline,
    MoreVertOutlined,
    StickyNote2Outlined
} from '@mui/icons-material'
import { TablePagination, Tooltip } from '@mui/material'
import { Popover } from 'antd'
import Table from 'components/atoms/Table'

const ListDocument = ({
    className,
    tableClassName,
    height,
    data = [],
    onEdit = () => { },
    onExport = () => { },
    onDelete = () => { }
}) => {

    const paginationRef = useRef();
    const [tableHeight, setTableHeight] = useState(height ?? 0);

    useEffect(() => {
      if(paginationRef?.current) {
        setTableHeight(height - paginationRef?.current?.clientHeight)
      }
    }, [paginationRef?.current !== null, height])
  
    const optionsPopover = [
        {
            title: 'Edit',
            callback: onEdit,
            role: ['admin', 'editor']
        },
        {
            title: 'Delete case',
            callback: onDelete,
            role: ['admin']
        },
        {
            title: 'Export notes',
            callback: onExport,
            role: ['admin', 'editor']
        }
    ]
    function handleClickMenu(callback, row) {
        return callback(row)
    }
    const columns = [
        {
            name: 'Name',
            cell: (row) => (
                <div className="flex gap-2 items-center">
                    {row?.status ==="success" && <div className='w-2 h-2 bg-[#00B47E] rounded-full'/>}
                    {row?.status ==="error" && <div className='w-2 h-2 bg-[#E53E3E] rounded-full'/>}
                    <Tooltip title={row?.name}>
                        <span className="line-clamp-1">{row?.name}</span>
                    </Tooltip>
                </div>
            ),
            selector: (row) => row?.name,
            sortable: true
        },
        {
            name: 'Opened Chats',
            cell: (row) => (
                <div className="flex gap-2 items-center">
                    {row?.totalDocument}{' '}
                    <ChatBubbleOutline className="!w-4 !h-[18px] !text-[#828282]" />
                </div>
            ),
            selector: (row) => row?.totalDocument,
            sortable: true,
            // width: '120px',
        },
        {
            name: 'Opened Notes',
            cell: (row) => (
                <div className="flex gap-2 items-center">
                    {row?.totalNote} <StickyNote2Outlined className="!w-4 !h-[18px] !text-[#828282]" />{' '}
                </div>
            ),
            // width: '120px',
            selector: (row) => row?.totalNote,
            sortable: true
        },
        {
            name: 'Loading Date',
            selector: (row) => row?.createdAt,
            sortable: true,
            width: '150px',
        },
        {
            name: '',
            cell: () => (
                <div className="flex justify-center items-center">
                    <ArrowForward className="!text-[#595961] cursor-pointer" />
                </div>
            ),
            width: '80px',
            sortable: false
        },
        {
            name: '',
            cell: (row) => (
                <Popover
                    content={
                        <div className="w-[158px] h-full flex flex-col text-black">
                            {optionsPopover.map(
                                (option, index) =>
                                    option.role.includes('admin') && (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                handleClickMenu(option.callback, row)
                                            }}
                                            className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                                            {option.title}
                                        </div>
                                    )
                            )}
                        </div>
                    }
                    trigger="click"
                    placement="leftTop"
                    arrow={false}>
                    <MoreVertOutlined className={`cursor-pointer hover:text-[#0049EE]`} />
                </Popover>
            ),
            width: '80px',
            sortable: false
        }
    ]

    return (
        <div className='w-full'>
            <div>
                <Table
                    tableStyles={{ height: tableHeight, overflowY: 'scroll' }}
                    hidePagination
                    columns={columns}
                    data={data}
                    headStyles={{ backgroundColor: 'white' }}
                    fixedHeader
                />
            </div>
            <div className="pr-6 mt-1" ref={paginationRef}>
                <TablePagination
                    component="div"
                    count={9}
                    rowsPerPageOptions={[9, 30, 50, 100]}
                    page={0}
                    onPageChange={() => { }}
                    rowsPerPage={9}
                    onRowsPerPageChange={() => { }}
                />
            </div>
        </div>
    )
}

export default ListDocument
