import React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { passwordValidation } from 'utils/passwordValidation'
import { Typography } from 'antd'
import PasswordFields from 'components/molecules/PasswordFields'
import CustomButton from 'components/atoms/CustomButton'
import { useMutation } from 'react-query'
import { putChangePassword } from 'api'
import useAuth from 'hooks/useAuth'
import { errorObj } from 'utils/handleError'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'

const schema = z
  .object({
    old_password: z.string().min(1, { message: 'Please input your current password' }),
    new_password: z
      .string()
      .min(10, {
        message: 'Password does not meet the safety guidelines'
      })
      .regex(passwordValidation(), 'Password does not meet the safety guidelines'),
    password_confirmation: z.string().min(1, { message: 'password is required' })
  })
  .refine((data) => data.new_password === data.password_confirmation, {
    message: 'Passwords do not match',
    path: ['password_confirmation']
  })
const ChangePasswordProfileForm = () => {
  const { getAccessToken } = useAuth()
  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
    control
  } = useForm({
    defaultValues: {
      old_password: '',
      new_password: '',
      password_confirmation: ''
    },
    mode: 'onChange',
    resolver: zodResolver(schema)
  })

  const putChangePasswordMutation = useMutation({
    mutationKey: 'putChangePassword',
    mutationFn: (data) => putChangePassword(getAccessToken(), data),
    onSuccess: () => {
      showSuccessMessage('Password changes saved')
      reset()
    },
    onError: (err) => {
      const errObj = errorObj(err)
      showErrorMessage('Password was not possible to save the changes')
    }
  })

  const onSubmit = (data) => {
    console.log(data)
    putChangePasswordMutation.mutate(data)
  }

  return (
    <div className="flex flex-col gap-6 max-w-72">
      <Typography.Title className="!text-white !text-lg !font-manrope">
        Edit Password
      </Typography.Title>

      <div className="flex flex-col gap-4">
        <PasswordFields
          autoComplete="new-password"
          theme="admin"
          control={control}
          label={'Current Password'}
          name={'old_password'}
          errors={errors}
        />
        <PasswordFields
          theme="admin"
          autoComplete="off"
          control={control}
          label={'Password'}
          name={'new_password'}
          errors={errors}
        />
        <PasswordFields
          theme="admin"
          autoComplete="off"
          control={control}
          label={'Repeat Password'}
          name={'password_confirmation'}
          errors={errors}
        />
        <CustomButton
          className={'!max-w-36'}
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(onSubmit)}
          buttonColor="primary"
          fullWidth>
          Save changes
        </CustomButton>
      </div>
    </div>
  )
}

export default ChangePasswordProfileForm
