import React, { useEffect, useRef, useState } from 'react'
import { Layout, ConfigProvider } from 'antd'
import { Outlet } from 'react-router-dom'
import SidebarUser from 'components/molecules/user/SidebarUser'

const UserTemplate = () => {

  const heightRef = useRef();
  const [layoutInfo, setLayoutInfo] = useState(0);

  useEffect(() => {
    setLayoutInfo(heightRef?.current?.clientHeight ?? 0)
  }, [heightRef?.current !== null])

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            siderBg: '#F7F7F8'
          }
        }
      }}>
      <Layout
        style={{ overflow: 'hidden', overflowY: 'hidden' }}
        className="flex h-screen flex-col">
        <Layout className="flex flex-1">
          <SidebarUser />
          <div className="w-full h-screen flex-1 p-2 pl-0 bg-[#F7F7F8] overflow-hidden" ref={heightRef}>
            <Outlet context={[layoutInfo]} />
          </div>
        </Layout>
      </Layout>
    </ConfigProvider>
  )
}

export default UserTemplate
