import { ThemeProvider, createTheme } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import React from 'react'

const AdminPagination = ({ count, currentPage, onChange, ...paginationProps }) => {
  const theme = createTheme({
    components: {
      MuiPagination: {
        styleOverrides: {
          root: {
            color: 'white'
          },
          ul: {
            color: 'white !important'
          }
        }
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: 'white',
            ':hover': {
              backgroundColor: '#FFFFFF14'
            },
            '&.Mui-selected': {
              backgroundColor: '#FFFFFF14'
            }
          }
        }
      }
    }
  })
  return (
    <ThemeProvider theme={theme}>
      <Pagination
        count={count}
        onChange={onChange}
        page={currentPage}
        shape="rounded"
        {...paginationProps}
      />
    </ThemeProvider>
  )
}

export default AdminPagination
