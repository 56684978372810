export const staticCases = [
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Benomar',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Marketplace',
    status: 'Dibattimento',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Alati',
    status: 'Judgment',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Risitano',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  },
  {
    caseName: 'Tumbarello',
    status: 'Preliminary inquiries',
    updatedAt: '02/07/2023',
    totalChat: 28,
    totalDocument: 10,
    totalNote: 10,
    regulation: 'Criminal'
  }
]
