import CustomButton from 'components/atoms/CustomButton'
import ModalAdmin from 'components/molecules/admin/ModalAdmin'
import React from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'
import TextFields from 'components/molecules/TextFields'
import { Error } from '@mui/icons-material'
import { Typography } from 'antd'
import { useSelector } from 'react-redux'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import { apiPost, apiPut } from 'api'

const schema = z.object({
  gptKey: z.string().min(1, { message: 'Gpt Key is required' }),
})

const ChangeGptKeyModal = ({ open, onClose, onSubmit, gptKey, refetch, data }) => {

  const token = useSelector(state => state.admin.access_token)

  const onValidateGptKey = async (newData) => {
    try {
      let res
      let body = {
        gpt_key: newData?.gptKey
      }

      res = await apiPost({ url: `company/validate-gpt-key`, token, data: body })

      if (res?.resStatus === 200 || res?.resStatus === 201) {
        onSubmitGptKey(body);
        // refetch()
      } else {
        showErrorMessage('The Secret key is not valid')
      }
    } catch (error) {
      showErrorMessage('The Secret key is not valid')
    }
  }

  const onSubmitGptKey = async (newData) => {
    try {
      let res
      let body = {
        ...newData
      }

      res = await apiPut({ url: `companies/${data?.id}/update-gpt-key`, token, data: body })

      if (res?.resStatus === 200 || res?.resStatus === 201) {
        // await onSubmit()
        showSuccessMessage('Secret key edited')
        refetch()
        closeModal()
      } else {
        showErrorMessage('The Secret key could not be edited')
      }
    } catch (error) {
      showErrorMessage('The Secret key could not be edited')
    }
  }

  const {
    handleSubmit,
    reset,
    formState: { errors, isValid },
    control,
    setError,
  } = useForm({
    defaultValues: {
      gptKey: gptKey ?? '',
    },
    resolver: zodResolver(schema)
  })
  
  const processForm = async (data) => {
    await onValidateGptKey(data)
    
  }
  function closeModal() {
    reset()
    onClose()
  }

  return (
    <ModalAdmin
      modalClassName={'h-full max-h-[366px] max-w-[460px]'}
      show={open}
      onClose={onClose}
      title={'Edit secret key'}>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(processForm)}
        className="flex flex-col justify-between h-[366px] ">
        <div className="flex flex-col gap-4 max-w-[300px]">
          <TextFields
            theme="admin"
            control={control}
            label={'Secret Gpt Key'}
            name={'gptKey'}
            placeholder="Please input GPT Key"
            // variant="filled"
            errors={errors}
          />
          <div className='flex'>
            Do you not have a secret Gpt key? &nbsp;
            <span className='font-semibold hover:cursor-pointer hover:underline'>Contact us</span>
          </div>
        </div>
        <div>
          <div className='bg-[#484848] mb-4 rounded-lg flex p-4 items-center'>
            <Error
              fontSize='large'
              color='inherit'
              className='text-white mr-2'
            />
            <div className='text-white'>
              <Typography.Text className='block text-white font-bold text-[16px]'>Attention!</Typography.Text>
              By changing the secret Gpt key, all chats added within the old environment will be deleted and no longer searchable
            </div>
          </div>
          {/* Btns */}
          <div className="flex gap-2 justify-end items-center">
            <CustomButton buttonColor="secondary" onClick={closeModal}>
              Cancel
            </CustomButton>
            <CustomButton disabled={!isValid} buttonType="submit">Save</CustomButton>
          </div>
        </div>
      </Box>
    </ModalAdmin>
  )
}

export default ChangeGptKeyModal
