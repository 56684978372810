import { useDispatch, useSelector } from 'react-redux'
import { adminActions, userActions } from 'store/reducers/auth'
import { isAdminPage } from 'utils/isAdminPage'

const useAuth = () => {
  const dispatch = useDispatch()
  const adminData = useSelector((state) => state.admin)
  const platformData = useSelector((state) => state.user)

  function onLogin(data, isAdmin) {
    const payload = {
      access_token: data.resData.data.access_token,
      role: data.resData.data.role,
      profile: data.resData.data.user,
      isFirstLogin: true
    }
    if (isAdmin) dispatch(adminActions.loginBackOffice(payload))
    else dispatch(userActions.loginPlatform(payload))
  }
  function onLogout() {
    return isAdminPage()
      ? dispatch(adminActions.logoutBackOffice())
      : dispatch(userActions.logoutPlatform())
  }
  function getAccessToken() {
    return isAdminPage() ? adminData.access_token : platformData.access_token
  }
  function getRoleUser() {
    return isAdminPage() ? adminData.role : platformData.role
  }
  function handleSetIsFirstLogin() {
    //FILL NULL FOR ADMIN FUNCTION
    return isAdminPage() ? null : dispatch(userActions.setIsFirstLogin({ isFirstLogin: false }))
  }
  function handleSetEmailAddress(email, updated_at) {
    //FILL NULL FOR PLATFORM FUNCTION
    return isAdminPage()
      ? dispatch(adminActions.setEmailAddress({ email: email, updated_at: updated_at }))
      : null
  }

  return {
    adminData,
    platformData,
    onLogout,
    onLogin,
    getAccessToken,
    getRoleUser,
    handleSetIsFirstLogin,
    handleSetEmailAddress
  }
}

export default useAuth
