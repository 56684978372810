import { Typography } from 'antd'
import ChangeEmailProfileForm from 'components/organisms/admin/ProfileForm/ChangeEmailProfileForm'
import ChangePasswordProfileForm from 'components/organisms/admin/ProfileForm/ChangePasswordProfileForm'
import React from 'react'
import { useSelector } from 'react-redux'

const ProfilePage = () => {
  const email = useSelector(state => state?.admin?.profile?.email)
  return (
    <div className="flex flex-col gap-6 ">
      <Typography.Title className="!text-3xl !font-manrope !text-white">Profile</Typography.Title>
      <div className="flex flex-col gap-10">
        <ChangeEmailProfileForm
          email={email}
        />
        <ChangePasswordProfileForm />
      </div>
    </div>
  )
}

export default ProfilePage
