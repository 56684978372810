import { Button } from 'antd'
import React from 'react'

import FilterListIcon from '@mui/icons-material/FilterList'
const FilterButton = ({ onClick }) => {
  return (
    <Button
      ghost
      className="flex justify-between items-center min-w-[200px] w-full text-sm text-[#666666] bg-[#FFFFFF0F] hover:"
      onClick={onClick}>
      <span className="font-manrope">Filters</span>
      <FilterListIcon />
    </Button>
  )
}

export default FilterButton
