import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '@mui/material'
import CustomButton from 'components/atoms/CustomButton'
import CountrySelectFields from 'components/molecules/CountrySelectFields'
import PhoneNumberFields from 'components/molecules/PhoneNumberFields'
import TextFields from 'components/molecules/TextFields'
import React from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

const schema = z.object({
  email: z.string(),
  phone: z.string(),
  address: z.string(),
  country: z.object({
    id: z.number(),
    name: z.string()
  }).optional().or(z.undefined()).or(z.null()),
  municipality: z.string(),
  zip_code: z.string(),
  province: z.string(),
})
const ContactsForm = ({ data, countries, onUpdate }) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm({
    defaultValues: {
      email: data?.email ?? '',
      phone: data?.phone ?? '',
      country: data?.country ?? undefined,
      address: data?.address ?? '',
      municipality: data?.municipality ?? '',
      zip_code: data?.zip_code ?? '',
      province: data?.province ?? '',
    },
    values: {
      email: data?.email ?? '',
      phone: data?.phone ?? '',
      country: data?.country ?? undefined,
      address: data?.address ?? '',
      municipality: data?.municipality ?? '',
      zip_code: data?.zip_code ?? '',
      province: data?.province ?? '',
    },
    resolver: zodResolver(schema)
  })

  const processForm = async (form) => {
    let newForms = {
      ...form,
    }
    if(form?.country) {
      newForms = {
        ...newForms,
        country: form?.country?.id
      }
    }
    onUpdate(newForms)
  }

  return (
    <Box
      noValidate
      component="form"
      className="flex flex-col gap-6 max-w-72"
      onSubmit={handleSubmit(processForm)}
    >
      <TextFields
        control={control}
        theme="admin"
        label={'Email'}
        placeholder={'input Email'}
        errors={errors}
        name={'email'}
      />
      <PhoneNumberFields
        control={control}
        theme="admin"
        label={'Telephone Number'}
        placeholder={'input phone number'}
        errors={errors}
        name={'phone'}
      />
      <CountrySelectFields
        control={control}
        countries={countries ?? []}
        fromBE={true}
        theme="admin"
        label={'Country'}
        errors={errors}
        name={'country'}
      />
      <TextFields
        control={control}
        theme="admin"
        label={'Address'}
        errors={errors}
        name={'address'}
      />
      <TextFields
        control={control}
        theme="admin"
        label={'Municipality'}
        errors={errors}
        name={'municipality'}
      />
      <div className="flex gap-4">
        <TextFields
          type="number"
          control={control}
          theme="admin"
          label={'ZIP CODE'}
          errors={errors}
          name={'zip_code'}
        />
        <TextFields
          control={control}
          theme="admin"
          label={'Province'}
          errors={errors}
          name={'province'}
        />
      </div>
      <CustomButton className={'!max-w-36'} disabled={!isValid} type="submit" buttonColor="primary">
        Save changes
      </CustomButton>
    </Box>
  )
}

export default ContactsForm
