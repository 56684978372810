import React from 'react'

const IcMove = ({ size = 17, ...props }) => {
    return (
        <svg width={size} height={size} {...props} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2010_32132)">
                <path d="M6.28992 4.24922L8.83992 1.69922L11.3899 4.24922" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.83923 1.69922L8.83987 15.9792" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.28992 13.4297L8.83992 15.9797L11.3899 13.4297" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.4299 6.28906L15.9799 8.83906L13.4299 11.3891" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.24995 6.28906L1.69995 8.83906L4.24995 11.3891" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1.69995 8.83984H15.9799" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2010_32132">
                    <rect width="16.32" height="16.32" fill="currentColor" transform="translate(0.679932 0.679688)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default IcMove