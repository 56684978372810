import React from 'react'

const IcCompany = ({ className, size = 20 }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8333 1.6665H7.5C6.58083 1.6665 5.83333 2.414 5.83333 3.33317V8.33317H4.16667C3.2475 8.33317 2.5 9.08067 2.5 9.99984V17.4998C2.5 17.7209 2.5878 17.9328 2.74408 18.0891C2.90036 18.2454 3.11232 18.3332 3.33333 18.3332H16.6667C16.8877 18.3332 17.0996 18.2454 17.2559 18.0891C17.4122 17.9328 17.5 17.7209 17.5 17.4998V3.33317C17.5 2.414 16.7525 1.6665 15.8333 1.6665ZM4.16667 9.99984H9.16667V16.6665H4.16667V9.99984ZM15.8333 16.6665H10.8333V9.99984C10.8333 9.08067 10.0858 8.33317 9.16667 8.33317H7.5V3.33317H15.8333V16.6665Z"
      fill="currentColor"
      fillOpacity="1"
    />
    <path
      d="M9.16665 5H10.8333V6.66667H9.16665V5ZM12.5 5H14.1666V6.66667H12.5V5ZM12.5 8.35917H14.1666V10H12.5V8.35917ZM12.5 11.6667H14.1666V13.3333H12.5V11.6667ZM5.83331 11.6675H7.49998V13.3342H5.83331V11.6675Z"
      fill="currentColor"
      fillOpacity="1"
    />
  </svg>
)

export default IcCompany;
