import React, { useEffect, useRef, useState } from 'react'
import {
  ArrowForward,
  InsertDriveFileOutlined,
  MoreVertOutlined,
  StickyNote2Outlined
} from '@mui/icons-material'
import { TablePagination, Tooltip } from '@mui/material'
import { Popover } from 'antd'
import Table from 'components/atoms/Table'

const ListChat = ({
  height,
  data = [],
  onRename = () => {},
  onDelete = () => {}
}) => {

  const paginationRef = useRef();
  const [tableHeight, setTableHeight] = useState(height ?? 0);

  const optionsPopover = [
    {
      title: 'Rename',
      callback: onRename,
      role: ['admin', 'editor']
    },
    {
      title: 'Delete',
      callback: onDelete,
      role: ['admin']
    }
  ]
  function handleClickMenu(callback, row) {
    return callback(row)
  }

  useEffect(() => {
    if(paginationRef?.current) {
      setTableHeight(height - paginationRef?.current?.clientHeight)
    }
  }, [paginationRef?.current !== null, height])

  const columns = [
    {
      name: 'Name',
      cell: (row) => (
        <div className="flex gap-2 items-center">
          <Tooltip title={row?.name}>
            <span className="line-clamp-1">{row?.name}</span>
          </Tooltip>
        </div>
      ),
      selector: (row) => row?.name,
      sortable: true
    },
    {
      name: 'Documents',
      cell: (row) => (
        <div className="flex gap-2 items-center">
          {row?.totalDocument}{' '}
          <InsertDriveFileOutlined className="!w-4 !h-[18px] !text-[#828282]" />
        </div>
      ),
      selector: (row) => row?.totalDocument,
      sortable: true
      // width: '120px',
    },
    {
      name: 'Created Notes',
      cell: (row) => (
        <div className="flex gap-2 items-center">
          {row?.totalNote} <StickyNote2Outlined className="!w-4 !h-[18px] !text-[#828282]" />{' '}
        </div>
      ),
      // width: '120px',
      selector: (row) => row?.totalNote,
      sortable: true
    },
    {
      name: 'Creation Date',
      selector: (row) => row?.createdAt,
      sortable: true,
      width: '150px'
    },
    {
      name: '',
      cell: () => (
        <div className="flex justify-center items-center">
          <ArrowForward className="!text-[#595961] cursor-pointer" />
        </div>
      ),
      width: '80px',
      sortable: false
    },
    {
      name: '',
      cell: (row) => (
        <Popover
          content={
            <div className="w-[158px] h-full flex flex-col text-black">
              {optionsPopover.map(
                (option, index) =>
                  option.role.includes('admin') && (
                    <div
                      key={index}
                      onClick={() => {
                        handleClickMenu(option.callback, row)
                      }}
                      className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                      {option.title}
                    </div>
                  )
              )}
            </div>
          }
          trigger="click"
          placement="leftTop"
          arrow={false}>
          <MoreVertOutlined className={`cursor-pointer hover:text-[#0049EE]`} />
        </Popover>
      ),
      width: '80px',
      sortable: false
    }
  ]

  return (
    <div className="w-full">
      <div>
        <Table
          tableStyles={{ height: tableHeight, overflowY: 'scroll' }}
          hidePagination
          columns={columns}
          data={data}
          headStyles={{ backgroundColor: 'white' }}
          fixedHeader
        />
      </div>
      <div className="pr-6 mt-1" ref={paginationRef}>
        <TablePagination
          component="div"
          count={9}
          rowsPerPageOptions={[9, 30, 50, 100]}
          page={0}
          onPageChange={() => {}}
          rowsPerPage={9}
          onRowsPerPageChange={() => {}}
        />
      </div>
    </div>
  )
}

export default ListChat
