import { LogoutOutlined, ShieldOutlined } from '@mui/icons-material'
import { getTC, putAcceptTC } from 'api'
import { CONTACTS_CONTENT, PROFILE_CONTENT, ROLE_SUPER_ADMIN, SECURITY_CONTENT } from 'constant'
import useAuth from 'hooks/useAuth'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import LogoutModal from './LogoutModal'
import TCModal from '../TCModal'
import { errorObj } from 'utils/handleError'
import { showSuccessMessage } from 'utils/showMessage'

const listStyle =
  'px-6 py-6 h-7 flex justify-start font-semibold text-[#595961] hover:text-white hover:bg-[#0049EE] items-center w-full rounded-md text-base cursor-pointer'
const SidebarProfile = ({ handleContent, currentContent }) => {
  const { getAccessToken, platformData, onLogout } = useAuth()
  const [logoutModal, setLogoutModal] = useState(false)
  const [tcModal, setTcModal] = useState(false)

  function openLogoutModal() {
    setLogoutModal(true)
  }
  function closeLogoutModal() {
    setLogoutModal(false)
  }
  function handleSubmitLogout() {
    showSuccessMessage('Successful logout')
    return onLogout()
  }
  // T&C
  useQuery({
    queryKey: ['getTC'],
    queryFn: () => getTC(getAccessToken()),
    onSuccess: (res) => {
      console.log(res)
      if (res.resData.data !== null) {
        return setTcModal((prev) => ({ ...prev, body: res.resData?.data?.terms_and_conditions }))
      }
    },
    onError: (err) => {
      const errObj = errorObj(err)
    }
  })
  function handleSubmitTcModal() {
    closeTcModal()
  }
  function openTcModal() {
    setTcModal((prev) => ({ ...prev, show: true }))
  }
  function closeTcModal() {
    setTcModal((prev) => ({ ...prev, show: false }))
  }

  return (
    <div className="flex flex-col justify-between flex-grow p-4 w-full">
      {/* Profile, Security & contacts */}
      <ul className="list-none w-full">
        <li
          onClick={handleContent.bind(this, PROFILE_CONTENT)}
          className={`${listStyle} ${
            currentContent === PROFILE_CONTENT ? 'bg-[#0049EE] text-white' : ''
          }`}>
          Profile
        </li>
        <li
          onClick={handleContent.bind(this, SECURITY_CONTENT)}
          className={`${listStyle} ${
            currentContent === SECURITY_CONTENT ? 'bg-[#0049EE] text-white' : ''
          }`}>
          Security
        </li>
        <li
          onClick={handleContent.bind(this, CONTACTS_CONTENT)}
          className={`${listStyle} ${
            currentContent === CONTACTS_CONTENT ? 'bg-[#0049EE] text-white' : ''
          }`}>
          Contacts
        </li>
      </ul>
      {/* T&C , Logout */}
      <ul className="list-none w-full">
        <li className={`${listStyle} gap-2`} onClick={openTcModal}>
          <ShieldOutlined /> <span>Terms and conditions</span>
        </li>
        <li className={`${listStyle} gap-2`} onClick={openLogoutModal}>
          <LogoutOutlined /> <span>Logout</span>
        </li>
      </ul>
      {/* Modal Logout */}
      {logoutModal && (
        <LogoutModal
          show={logoutModal}
          onLogout={handleSubmitLogout}
          closeModal={closeLogoutModal}
        />
      )}
      {/* Modal T&C */}
      {tcModal.show && (
        <TCModal
          body={tcModal.body}
          show={tcModal.show}
          onClose={onLogout}
          onSubmit={handleSubmitTcModal}
          onLogout={onLogout}
        />
      )}
    </div>
  )
}

export default SidebarProfile
