import { useLocation, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const RequireAuth = ({ children, isBackOffice }) => {
  const adminData = useSelector((state) => state.admin)
  const platformData = useSelector((state) => state.user)
  const location = useLocation()

  if (isBackOffice && !adminData.access_token) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />
  }
  if (!isBackOffice && !platformData.access_token)
    return <Navigate to="/login" state={{ from: location }} replace />

  return children
}

export default RequireAuth
