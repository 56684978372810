import { FormatListBulletedOutlined, GridOnOutlined } from '@mui/icons-material'
import { Paper, ThemeProvider, ToggleButton, ToggleButtonGroup, createTheme } from '@mui/material'
import { GRID_VIEW, LIST_VIEW } from 'constant'
import React from 'react'

const GridListToggleButton = ({ view, onToggleView }) => {
  const theme = createTheme({
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: 'white'
            },
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '5px',
            paddingRight: '5px',
            border: 'none'
          }
        }
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          grouped: {
            margin: '4px',
            border: 0,
            borderRadius: '4px'
          },
          lastButton: {
            margin: '4px',
            marginLeft: -1,
            borderLeft: '1px solid transparent'
          },
          middleButton: {
            margin: -1,
            borderLeft: '1px solid transparent'
          }
        }
      }
    }
  })
  return (
    <ThemeProvider theme={theme}>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: 'none',
          flexWrap: 'wrap',
          backgroundColor: '#E3E3E8',
          borderRadius: '4px'
        }}>
        <ToggleButtonGroup
          size="small"
          value={view}
          exclusive
          onChange={(event, newView) => {
            onToggleView(newView)
          }}>
          <ToggleButton value={GRID_VIEW} aria-label="grid">
            <GridOnOutlined className="!w-4 !h-4" />
          </ToggleButton>
          <ToggleButton value={LIST_VIEW} aria-label="list">
            <FormatListBulletedOutlined className="!w-4 !h-4" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Paper>
    </ThemeProvider>
  )
}

export default GridListToggleButton
