import { zodResolver } from '@hookform/resolvers/zod'
import { NullAvatar } from 'assets/images'
import CustomButton from 'components/atoms/CustomButton'
import TextFields from 'components/molecules/TextFields'
import useAuth from 'hooks/useAuth'
import React from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
const styleTextField =
  'flex flex-col gap-2 w-full max-w-[340px] border-solid border-0 border-b border-[#E3E3E8] text-lg text-[#828282] font-normal'
const schema = z.object({
  email: z.string().email({ message: 'Invalid email address' })
})
const ProfilePlatformContent = () => {
  const { platformData } = useAuth()
  const {
    handleSubmit,
    formState: { errors, isDirty, isValid },
    control
  } = useForm({
    defaultValues: {
      email: platformData?.profile?.email ? platformData?.profile?.email : ''
    },
    resolver: zodResolver(schema)
  })

  return (
    <div className="flex flex-col flex-grow gap-12">
      {/* Title */}
      <h1 className="text-black text-4xl font-medium">Profile</h1>
      {/* Content */}
      <div className="grid grid-cols-[404px_1fr] h-full w-full gap-8">
        {/* Left */}
        <div className="flex flex-grow flex-col border-solid border-0 border-r border-[#E3E3E8] gap-8">
          {/* title */}
          <h2 className="font-semibold text-lg">Profile picture</h2>
          {/* Avatar */}
          <div className="flex flex-col items-center leading-3 gap-4">
            <div className="rounded-full flex justify-center items-center w-52 h-52 bg-[#F7F7F8] border border-solid border-[#E3E3E8]">
              <img
                src={NullAvatar}
                className="object-contain"
                alt="avatar"
                aria-label="avatar profile"
              />
            </div>
            <span className="text-[#595961] text-sm font-normal ">Upload your profile picture</span>
          </div>
          <div className="flex justify-center items-center pt-2">
            <CustomButton>Upload Avatar</CustomButton>
          </div>
        </div>
        {/* Right */}
        <div className="flex flex-col flex-grow gap-8">
          {/* title */}
          <h2 className="font-semibold text-lg">General Information</h2>

          {/* Content */}
          <div className="flex flex-col flex-grow gap-16">
            {/* Information */}
            <div className="flex gap-4 flex-col">
              <div className={styleTextField}>
                <span className="text-base">Name</span>
                <span>Mario Rossi</span>
              </div>
              <div className={styleTextField}>
                <span className="text-base">Role</span>
                <span>Admin</span>
              </div>
              <div className={styleTextField}>
                <span className="text-base">Company</span>
                <span>Notomia</span>
              </div>
            </div>
            {/* Edit Email */}
            <div className="flex flex-col gap-8 max-w-[340px]">
              {/* title */}
              <h2 className="font-semibold text-lg">Email</h2>
              <div className="flex flex-col gap-4">
                <TextFields
                  theme="admin"
                  label={'Email'}
                  control={control}
                  autoComplete="off"
                  name={'email'}
                  errors={errors}
                />
                <div>
                  <CustomButton
                    buttonColor={isDirty && isValid ? 'primary' : 'secondary'}
                    disabled={!isDirty || !isValid}>
                    Save Changes
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfilePlatformContent
