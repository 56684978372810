import { toast } from 'react-toastify'

export const showSuccessMessage = (content) => {
  toast.success(`${content ?? 'Successfully'}`, {
    hideProgressBar: true,
    autoClose: 1000
  })
}

export const showErrorMessage = (content, duration) => {
  toast.error(`${content ?? 'Failed'}`, {
    hideProgressBar: true,
    autoClose: duration ?? 1000
  })
}

export const showWarningMessage = (content, duration) => {
  toast.warning(`${content ?? 'Be carefully!'}`, {
    hideProgressBar: true,
    autoClose: duration ?? 1000
  })
}
