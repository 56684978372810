import React from 'react'

const IcUsers = ({ className, size = 20 }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.22039 12.8874C2.00179 12.106 3.0616 11.667 4.16667 11.667H10.8333C11.9384 11.667 12.9982 12.106 13.7796 12.8874C14.561 13.6688 15 14.7286 15 15.8337V17.5003C15 17.9606 14.6269 18.3337 14.1667 18.3337C13.7064 18.3337 13.3333 17.9606 13.3333 17.5003V15.8337C13.3333 15.1706 13.0699 14.5347 12.6011 14.0659C12.1323 13.5971 11.4964 13.3337 10.8333 13.3337H4.16667C3.50363 13.3337 2.86774 13.5971 2.3989 14.0659C1.93006 14.5347 1.66667 15.1706 1.66667 15.8337V17.5003C1.66667 17.9606 1.29357 18.3337 0.833333 18.3337C0.373096 18.3337 0 17.9606 0 17.5003V15.8337C0 14.7286 0.438987 13.6688 1.22039 12.8874Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.49992 3.33366C6.11921 3.33366 4.99992 4.45295 4.99992 5.83366C4.99992 7.21437 6.11921 8.33366 7.49992 8.33366C8.88063 8.33366 9.99992 7.21437 9.99992 5.83366C9.99992 4.45295 8.88063 3.33366 7.49992 3.33366ZM3.33325 5.83366C3.33325 3.53247 5.19873 1.66699 7.49992 1.66699C9.80111 1.66699 11.6666 3.53247 11.6666 5.83366C11.6666 8.13485 9.80111 10.0003 7.49992 10.0003C5.19873 10.0003 3.33325 8.13485 3.33325 5.83366Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8599 12.3996C15.975 11.954 16.4295 11.686 16.8751 11.8011C17.769 12.0319 18.5609 12.553 19.1265 13.2827C19.6922 14.0123 19.9994 14.9091 20.0001 15.8323L20.0001 17.4996C20.0001 17.9599 19.627 18.3329 19.1668 18.3329C18.7065 18.3329 18.3335 17.9599 18.3335 17.4996V15.8336C18.3335 15.8335 18.3335 15.8337 18.3335 15.8336C18.333 15.2797 18.1486 14.7415 17.8093 14.3038C17.4699 13.866 16.9948 13.5533 16.4585 13.4148C16.0128 13.2998 15.7449 12.8452 15.8599 12.3996Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.526 2.40125C12.6402 1.95539 13.0941 1.6865 13.54 1.80066C14.4363 2.03014 15.2307 2.55139 15.7979 3.28223C16.3652 4.01307 16.6732 4.91194 16.6732 5.83711C16.6732 6.76229 16.3652 7.66116 15.7979 8.392C15.2307 9.12284 14.4363 9.64409 13.54 9.87357C13.0941 9.98773 12.6402 9.71884 12.526 9.27298C12.4118 8.82713 12.6807 8.37315 13.1266 8.25899C13.6643 8.1213 14.141 7.80855 14.4814 7.37005C14.8217 6.93154 15.0065 6.39222 15.0065 5.83711C15.0065 5.28201 14.8217 4.74269 14.4814 4.30418C14.141 3.86568 13.6643 3.55293 13.1266 3.41524C12.6807 3.30108 12.4118 2.8471 12.526 2.40125Z"
      fill="currentColor"
    />
  </svg>
)

export default IcUsers;
