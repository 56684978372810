import CustomButton from 'components/atoms/CustomButton'
import ModalAdmin from 'components/molecules/admin/ModalAdmin'
import React from 'react'

const RetrieveProfileUsersModal = ({ show, onClose, onRetrieveProfile, isLoading }) => {
  return (
    <ModalAdmin show={show} onClose={isLoading ? () => {} : onClose} title={'Retrieve Profile'}>
      <div className="flex flex-col gap-8">
        <span className="font-manrope text-base text-[#999999]">
          Are you sure you want to retrieve this {"user's"} profile? Your request for deletion will
          be cancelled
        </span>

        <div className="flex gap-2 justify-end items-center">
          <CustomButton buttonColor="secondary" disabled={isLoading} onClick={onClose}>
            Cancel
          </CustomButton>
          <CustomButton
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => {
              onRetrieveProfile()
            }}>
            Retrieve
          </CustomButton>
        </div>
      </div>
    </ModalAdmin>
  )
}

export default RetrieveProfileUsersModal
