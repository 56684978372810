import { useMemo } from 'react'
import { isAdminPage } from 'utils/isAdminPage'

function OtpInput({ value, valueLength, onChange, isError }) {
  const valueItems = useMemo(() => {
    const valueArray = value.split('')
    const items = []

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i]

      if (/^\d+$/.test(char)) {
        items.push(char)
      } else {
        items.push('')
      }
    }

    return items
  }, [value, valueLength])

  const focusToNextInput = (target) => {
    const nextElementSibling = target.nextElementSibling

    if (nextElementSibling) {
      nextElementSibling.focus()
    }
  }

  const focusToPrevInput = (target) => {
    const previousElementSibling = target.previousElementSibling

    if (previousElementSibling) {
      previousElementSibling.focus()
    }
  }

  const inputOnChange = (e, idx) => {
    var target = e.target
    var targetValue = target.value.trim()
    var isTargetValueDigit = /^\d+$/.test(targetValue)

    if (!isTargetValueDigit && targetValue !== '') {
      return
    }

    const nextInputEl = target.nextElementSibling

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return
    }

    targetValue = isTargetValueDigit ? targetValue : ' '

    var targetValueLength = targetValue.length

    if (targetValueLength === 1) {
      var newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1)
      onChange(newValue)
      if (!isTargetValueDigit) {
        return
      }
      var nextElementSibling = target.nextElementSibling
      if (nextElementSibling) {
        nextElementSibling.focus()
      }
      focusToNextInput(target)
    } else if (targetValueLength === valueLength) {
      onChange(targetValue)
      target.blur()
    }
  }

  const inputOnKeyDown = (e) => {
    var target = e.target

    if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
      e.preventDefault()
      return focusToNextInput(target)
    }

    if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
      e.preventDefault()
      return focusToPrevInput(target)
    }

    const targetValue = target.value

    if (e.key !== 'Backspace' || targetValue !== '') {
      return
    }
    var previousElementSibling = target.previousElementSibling
    if (previousElementSibling) {
      previousElementSibling.focus()
    }
  }

  const inputOnFocus = (e) => {
    const { target } = e

    const prevInputEl = target.previousElementSibling

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus()
    }

    target.setSelectionRange(0, target.value.length)
  }

  return (
    <div className="otp-group">
      {valueItems.map((digit, idx) => (
        <input
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          placeholder="-"
          maxLength={valueLength}
          className={`${isError ? '!border-red-300 !border-2' : ''} otp-input ${
            isAdminPage() ? 'bg-[#FFFFFF0F] !text-white' : ''
          }`}
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
        />
      ))}
    </div>
  )
}

export default OtpInput
