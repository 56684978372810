import React, { useEffect, useRef, useState } from 'react'
import { Add } from '@mui/icons-material'
import { Tabs, Tab } from '@mui/material'
import { Typography } from 'antd'
import { IcSplate } from 'assets/icons'
import ListNotes from 'components/molecules/user/cases/ListNotes'

const NotesContainer = ({ setTabNote, tabNote, data, height }) => {

    const noteHeadRef = useRef();
    const [listNotesHeight, setListNotesHeight] = useState(height ?? 0);

    useEffect(() => {
        if(noteHeadRef?.current) {
            setListNotesHeight(height - noteHeadRef?.current?.clientHeight)
        }
    }, [noteHeadRef?.current !== null, height])

    return (
        <div className='w-full col-span-3'>
            <div className='flex justify-between items-center' ref={noteHeadRef}>
                <div>
                    <Typography.Text className='text-[24px] flex items-center'>
                        Notes
                        <Add className='ml-3' />
                    </Typography.Text>
                    <Tabs
                        textColor='inherit'
                        sx={{
                            minHeight: 0,
                            height: '42px'
                        }}
                        TabIndicatorProps={{ style: { borderRadius: 20, height: 5, margin: 0, marginTop: 0, backgroundColor: '#0049EE' } }}
                        value={tabNote}
                        onChange={(_, tabName) => {
                            setTabNote(tabName)
                        }}
                    >
                        <Tab
                            disableRipple
                            fullWidth={false}
                            sx={{
                                alignItems: 'start',
                                minWidth: 0,
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                textTransform: 'none',
                                padding: 0,
                                marginRight: 2
                            }}
                            label="Insights"
                            value={0}
                        />
                        <Tab
                            disableRipple
                            fullWidth={false}
                            sx={{
                                alignItems: 'start',
                                minWidth: 0,
                                fontFamily: 'Inter',
                                fontWeight: 500,
                                textTransform: 'none',
                                padding: 0
                            }}
                            label="To do list"
                            value={1}
                        />
                    </Tabs>
                </div>
                <div
                    className="cursor-pointer rounded-lg border border-solid border-[#E3E3E8] bg-white text-center px-3 py-2 mr-2"
                >
                    <Typography.Text className='text-[#828282] text-[14px] font-bold block'>57</Typography.Text>
                    <Typography.Text className='text-[#828282] text-[11px] block mb-1'>Notes</Typography.Text>
                    <IcSplate size={11} />
                </div>
            </div>
            <ListNotes
                height={height}
                data={data}
                value={tabNote}
            />
        </div>
    )
}

export default NotesContainer