import React, { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Checkbox } from '@mui/material'
import CustomButton from 'components/atoms/CustomButton'
import RichTextEditor from 'components/molecules/admin/termconditions/RichTextEditor'
import AdminHeader from 'components/molecules/admin/AdminHeader'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { z } from 'zod'
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { IcClose, IcCopy, IcMove } from 'assets/icons'
import {
  restrictToVerticalAxis,
  restrictToParentElement
} from '@dnd-kit/modifiers'
import { Add } from '@mui/icons-material'
import { ConfigProvider, Menu, Popover } from 'antd'
import TextFields from 'components/molecules/TextFields'
import { getPrivacyPolicy, updatePrivacyPolicy } from 'api'
import { useSelector } from 'react-redux'
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage'
import { useQuery } from 'react-query'

const scheme = z.object({
  data: z.array(
    z.object({
      key: z.string(),
      value: z.string().min(1, { message: 'Field Harus diisi ' })
    })
  )
})

function SortableItem(props) {
  const { setNodeRef, transform, transition, ...rest } = useSortable({
    id: props.id
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div ref={setNodeRef} style={style}>
      {props.children({ ...rest })}
    </div>
  )
}

const PrivacyPolicyPage = () => {
  const [open, setOpen] = useState(false);
  const token = useSelector(state => state.admin.access_token)
  const [sendNotif, setSendNotif] = useState(false)


  const { data } = useQuery({
    queryKey: [
      'privacy-policy-page',
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getPrivacyPolicy(token)
      return res
    },
    onSuccess: (res) => {
      setSendNotif(res?.resData?.data?.send_notification)
    }
  })

  const { handleSubmit, control, formState: { errors }, getValues } = useForm({
    resolver: zodResolver(scheme),
    defaultValues: {
      data: data?.resData?.data?.privacy_policies ?? [
        {
          key: 'title',
          value: ''
        },
        {
          key: 'sub_title',
          value: ''
        },
        {
          key: 'paragraph',
          value: ''
        }
      ]
    },
    values: {
      data: data?.resData?.data?.privacy_policies ?? [
        {
          key: 'title',
          value: ''
        },
        {
          key: 'sub_title',
          value: ''
        },
        {
          key: 'paragraph',
          value: ''
        }
      ]
    },
  })

  const modifiers = [restrictToVerticalAxis, restrictToParentElement]
  const { fields, move, insert, remove } = useFieldArray({ control, name: 'data' })

  const processForm = async (data) => {
    try {
      await updatePrivacyPolicy(token, {
        send_notification: sendNotif,
        privacy_policies: data?.data
      })
      showSuccessMessage('Privacy policy updated')
    } catch(error) {
      console.log("err", error)
      showErrorMessage('Update Failed')
    }
  }


  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const menuProfile = [
    {
      key: 'menu-title',
      label: <div onClick={() => {
        insert(fields.length, {
          key: 'title',
          value: ''
        })
        setOpen(false)
      }}>Title</div>
    },
    {
      key: 'menu-subtitle',
      label: <div onClick={() => {
        insert(fields.length, {
          key: 'subtitle',
          value: ''
        })
        setOpen(false)
      }}>Subtitle</div>
    },
    {
      key: 'menu-paragraph',
      label: <div onClick={() => {
        insert(fields.length, {
          key: 'paragraph',
          value: ''
        })
        setOpen(false)
      }}>Paragraph</div>
    }
  ]


  return (
    <div className="h-full w-full overflow-y-auto">
      <AdminHeader
        className='mb-4'
        title={(
          <Popover
            placement='rightBottom'
            content={
              <ConfigProvider
                theme={{
                  components: {
                    Menu: {
                      itemBg: '#1A1A1A',
                      itemSelectedBg: '#282828',
                      itemHoverBg: '#282828',
                      itemActiveBg: '#282828',
                      itemMarginInline: 0,
                      itemBorderRadius: 4
                    }
                  }
                }}>
                <div className="w-[150px] bg-[#1A1A1A]">
                  <Menu
                    selectable={false}
                    mode="inline"
                    style={{
                      borderRight: 0
                    }}
                    items={menuProfile}
                  />
                </div>
              </ConfigProvider>
            }
            arrow={false}
            trigger="click"
            open={open}
            onOpenChange={() => setOpen(!open)}>
            <div className='flex items-center'>
              Privacy Policy
              <div className='ml-3 h-5 w-5 flex items-center justify-center rounded-full bg-[#191919] hover:cursor-pointer hover:opacity-45'>
                <Add
                  className='text-sm'
                  // fontSize='small'
                  sx={{
                    fontSize: 10
                  }}
                />
              </div>
            </div>
          </Popover>
        )}
        hideFunc
      />
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(processForm)}
        className="flex flex-col justify-between min-h-[466px]">
        <div className="flex flex-col gap-6 w-[60%] mt-4">
          <DndContext
            modifiers={modifiers}
            onDragEnd={(event) => {
              const { active, over } = event
              if (over && active.id !== over?.id) {
                const activeIndex = active.data.current?.sortable?.index
                const overIndex = over.data.current?.sortable?.index
                if (activeIndex !== undefined && overIndex !== undefined) {
                  move(activeIndex, overIndex)
                }
              }
            }}
          >
            <SortableContext
              items={fields}
              sensors={sensors}
              strategy={verticalListSortingStrategy}
            >
              {fields.map((item, index) => (
                <React.Fragment key={`sortableitem-${item.id}`}>
                  <SortableItem id={item.id}>
                    {({ attributes, listeners }) => {
                      const isCanRemove = getValues('data').filter(val => val.key === item.key).length > 1
                      return (
                        <div className="w-full flex flex-col gap-2">
                          <span className={`text-[#FFFFFFA6] capitalize`}>{item?.key === 'sub_title'? 'Subtitle' : item?.key}</span>
                          <div className='flex w-full gap-4'>
                            {item?.key !== 'paragraph' && (
                              <TextFields
                                control={control}
                                inArray={true}
                                name={`data[${index}].value`}
                                errors={errors?.data?.[index]?.value}
                              />
                            )}
                            {item?.key === 'paragraph' && (
                              <RichTextEditor
                                className='flex-1'
                                control={control}
                                inArray={true}
                                name={`data[${index}].value`}
                                errors={errors?.data?.[index]?.value}
                              />
                            )}
                            <div className='flex gap-3'>
                              <IcMove
                                className={'text-[#666666] hover:cursor-move hover:text-white'}
                                size={20}
                                {...attributes}
                                {...listeners}
                              />
                              <IcCopy
                                className={'text-[#666666] hover:cursor-pointer hover:text-white'}
                                size={20}
                                onClick={() => {
                                  insert(index + 1, {
                                    key: item?.key,
                                    value: ''
                                  })
                                }}
                              />
                              <IcClose
                                className={`${!isCanRemove ? 'text-[#666] hover:text[#666]' : 'text-[#666666] hover:cursor-pointer hover:text-white'}`}
                                size={20}
                                onClick={() => {
                                  if (isCanRemove) {
                                    remove(index)
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  </SortableItem>
                </React.Fragment>
              ))}
            </SortableContext>
          </DndContext>
        </div>
        <div className="flex gap-2 mt-14 items-center">
          <Checkbox
            color="primary"
            checked={sendNotif}
            onChange={(event) => setSendNotif(event.target.checked)}
            value={sendNotif}
          />
          <span className='text-[#FFFFFFA6] text-md'>Send users notification for new revision</span>
        </div>
        <div className="mt-14">
          <CustomButton type="submit">Save Changes</CustomButton>
        </div>
      </Box>
    </div>
  )
}

export default PrivacyPolicyPage
