import { Input } from 'antd'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { isAdminPage } from 'utils/isAdminPage'
const SearchInput = ({ onSearch, variant = 'borderless', ...inputProps }) => {
  return (
    <Input
      {...inputProps}
      variant={variant}
      placeholder="Search"
      className={isAdminPage() ? ' bg-[#FFFFFF0F] rounded-md' : 'rounded-md'}
      onChange={onSearch}
      allowClear
      suffix={<SearchIcon />}
    />
  )
}

export default SearchInput
