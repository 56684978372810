import { apiResponse } from 'api/api-response'
import { rootService } from 'api/api-root'

export const apiDelete = async ({ token, data, url }) => {
  let headers
  let obj = apiResponse()

  if (token) {
    headers = {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  } else {
    headers = {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }

  const res = await rootService.delete(`/${url}`, {
    headers,
    data
  })
  obj.resData = res.data
  obj.resStatus = res.status
  return obj
}
