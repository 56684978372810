import { apiResponse } from 'api/api-response'
import { rootService } from 'api/api-root'

export const apiPost = async ({ token, data, url, isFormData }) => {
  let headers
  let obj = apiResponse()

  if (token) {
    if (isFormData) {
      headers = {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    } else {
      headers = {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
  } else {
    headers = {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }

  const res = await rootService.post(`/${url}`, data, { headers })
  obj.resData = res?.data
  obj.resStatus = res?.status
  return obj
}
