import { Checkbox, ConfigProvider, Select } from 'antd'
import ErrorMessage from 'components/atoms/ErrorMessage'
import React from 'react'
import { Controller } from 'react-hook-form'

const MultipleSelectFields = ({
  control,
  label,
  labelClassName,
  name,
  options = [],
  handleOnChange,
  state,
  errors,
  className,
  isShowTotalSelect = false,
  totalSelectedItems = 0,
  isRequired = false,
  ...selectProps
}) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorTextPlaceholder: '#999999',
              colorText: 'white',
              activeBg: 'rgba(255, 255, 255, 0.40)'
            },
            Checkbox: {
              colorText: 'white'
            },
            Select: {
              colorTextPlaceholder: '#999999',
              selectorBg: '#FFFFFF0F',
              colorText: 'white',
              colorIcon: '#999999',
              fontFamily: "'Manrope',sans-serif",
              optionActiveBg: '#FFFFFF0F',
              colorPrimary: '#999999',
              optionSelectedColor: '#999999'
            }
          }
        }}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <span className={`${errors?.[name] ? '!text-red-500' : ''} } ${labelClassName}`}>
                {label}
              </span>
              <Select
                showSearch={true}
                maxTagCount={isShowTotalSelect ? 0 : undefined}
                maxTagPlaceholder={() => <span>{totalSelectedItems} selected</span>}
                mode="multiple"
                optionFilterProp={'label'}
                className={`!bg-[#FFFFFF0F] !text-[#999999] ${className}`}
                popupClassName="!bg-[#292929] !text-[#999999]"
                optionLabelProp="label"
                {...selectProps}
                {...field}>
                {options.length > 0 &&
                  options.map((option, index) => (
                    <Select.Option key={index} value={option?.value} label={option?.label}>
                      <div className="flex gap-2">
                        <Checkbox checked={state?.indexOf(option?.value) > -1}></Checkbox>
                        {option?.label}
                      </div>
                    </Select.Option>
                  ))}
              </Select>
            </>
          )}
        />
      </ConfigProvider>
      {errors[name] ? <ErrorMessage errorMessage={errors[name]?.message} /> : null}
    </div>
  )
}

export default MultipleSelectFields
