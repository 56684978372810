import React from 'react'

const IcTNC = ({ className, size = 20 }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 1.25L2.5 3.75V11.25C2.5 15.3926 5.85742 18.75 10 18.75C14.1426 18.75 17.5 15.3926 17.5 11.25V3.75L10 1.25ZM16.0938 11.25C16.0938 14.6152 13.3652 17.3438 10 17.3438C6.63477 17.3438 3.90625 14.6152 3.90625 11.25V4.80469L10 2.65625L16.0938 4.80469V11.25Z"
      fill="currentColor"
    />
    <path
      d="M7.39064 9.27881C7.32547 9.21334 7.24801 9.16139 7.16271 9.12595C7.07741 9.0905 6.98594 9.07225 6.89357 9.07225C6.8012 9.07225 6.70973 9.0905 6.62443 9.12595C6.53913 9.16139 6.46167 9.21334 6.3965 9.27881C6.33103 9.34398 6.27908 9.42144 6.24364 9.50674C6.20819 9.59204 6.18994 9.68351 6.18994 9.77588C6.18994 9.86825 6.20819 9.95972 6.24364 10.045C6.27908 10.1303 6.33103 10.2078 6.3965 10.2729L8.92384 12.8003L8.96486 12.8413C9.0265 12.9031 9.09972 12.9521 9.18032 12.9855C9.26092 13.0189 9.34733 13.0361 9.43458 13.0361C9.52184 13.0361 9.60825 13.0189 9.68885 12.9855C9.76945 12.9521 9.84267 12.9031 9.90431 12.8413L14.2695 8.47607C14.3313 8.41443 14.3803 8.34121 14.4137 8.26061C14.4472 8.18001 14.4644 8.09361 14.4644 8.00635C14.4644 7.91909 14.4472 7.83269 14.4137 7.75208C14.3803 7.67148 14.3313 7.59827 14.2695 7.53662L14.2149 7.48193C14.1532 7.42017 14.08 7.37118 13.9994 7.33775C13.9188 7.30432 13.8324 7.28711 13.7451 7.28711C13.6579 7.28711 13.5715 7.30432 13.4909 7.33775C13.4103 7.37118 13.337 7.42017 13.2754 7.48193L9.43361 11.3218L7.39064 9.27881Z"
      fill="currentColor"
    />
  </svg>
)

export default IcTNC