export const staticStatusCase = [
  {
    statusName: 'Preliminary inquiries',
    colorStatus: '#B700ED'
  },
  {
    statusName: 'Precautionary measures',
    colorStatus: '#00A2ED'
  },
  {
    statusName: 'Preventivo',
    colorStatus: '#0049EE'
  },
  {
    statusName: 'Riesame',
    colorStatus: '#EDA700'
  },
  {
    statusName: 'Dibattimento',
    colorStatus: '#ED6D00'
  },
  {
    statusName: 'Archived',
    colorStatus: '#828282'
  }
]
