import { apiPost } from 'api/api-post'
import { urls } from 'api/api-url'
import { PLATFORM } from 'constant'

export const postLoginPlatform = async (data) => {
  return apiPost({
    url: urls.auth.login,
    data: { email: data?.email, password: data?.password, portal: PLATFORM }
  })
}
