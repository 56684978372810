import React from 'react'
import { LoginForm } from 'components/organisms/LoginForm'
import { Navigate, useLocation } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import { isAdminPage } from 'utils/isAdminPage'
import LoginFormTemplate from 'components/templates/LoginFormTemplate'

const LoginPage = () => {
  const { platformData, adminData } = useAuth()
  const location = useLocation()
  if (isAdminPage()) {
    if (adminData.access_token) {
      return <Navigate to="/admin/companies" state={{ from: location }} replace />
    }
  } else if (platformData.access_token)
    return <Navigate to="/" state={{ from: location }} replace />

  return (
    <LoginFormTemplate>
      <div className="max-w-80 h-full flex flex-col justify-center items-center gap-10">
        {/* Typography */}
        <div className="flex flex-col justify-center items-center gap-2 ">
          <p
            className={`font-bold text-lg  text-h4 ${isAdminPage() ? 'text-white' : 'text-black'}`}>
            Login
          </p>
          <p className={`${isAdminPage() ? 'text-[#FFFFFF7A]' : 'text-[#595961]'} text-body1`}>
            {isAdminPage()
              ? 'Enter your email and password to log in'
              : 'Enter the email and password previously provided'}
          </p>
        </div>

        {/* Form */}
        <LoginForm />
      </div>
    </LoginFormTemplate>
  )
}

export default LoginPage
