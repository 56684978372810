import React from 'react'
import { NavLink } from 'react-router-dom'
import { Layout } from 'antd'
import IcFolder from 'assets/icons/IcFolder'
import { IcRegulation } from 'assets/icons'
import { Avatar } from 'assets/images'
const { Sider } = Layout

const activeLinkStyle =
  'flex no-underline mx-auto rounded-xl w-14 h-14 justify-center items-center px-2 py-3 border border-solid border-[#E3E3E8] bg-white'
const nonActiveLinkStyle =
  'flex no-underline mx-auto rounded-xl w-14 h-14 justify-center items-center px-2 py-3 border border-solid border-[#0000000A] bg-[#EDEDEF] hover:bg-white hover:border-[#E3E3E8]'

const SidebarUser = () => {
  return (
    <Sider width={100} className="overflow-x-hidden">
      {/* Logo Wrapper */}
      <div className="flex justify-center items-center py-5">
        <div className="w-14 h-14 rounded-md bg-blue-700 "></div>
      </div>
      {/* Sidebar Navigation */}
      <div className="flex flex-col gap-8">
        {/* Workspace */}
        <NavLink
          to="/cases"
          className={
            window.location.pathname == '/' || window.location.pathname.includes('cases')
              ? activeLinkStyle
              : nonActiveLinkStyle
          }>
          <IcFolder
            isActive={window.location.pathname == '/' || window.location.pathname.includes('cases')}
          />
        </NavLink>
        {/* Regulations */}
        <NavLink
          to="/regulations"
          className={({ isActive }) => (isActive ? activeLinkStyle : nonActiveLinkStyle)}>
          {({ isActive }) => <IcRegulation isActive={isActive} />}
        </NavLink>
      </div>
      <div className="fixed bottom-0 my-4 w-[100px] flex justify-center items-center">
        <NavLink
          to={'/profile'}
          className="bg-blue-700 w-14 h-14 flex items-center justify-center rounded-full text-white font-bold mr-2 hover:cursor-pointer">
          <img src={Avatar} className="w-full h-full object-cover aspect-square" alt="" />
        </NavLink>
      </div>
    </Sider>
  )
}

export default SidebarUser
