import React, { useEffect, useRef, useState } from 'react'
import { Typography, ConfigProvider, Select, Popover } from 'antd'
import {
  AccountCircle,
  Add,
  Balance,
  CalendarToday,
  ChatBubbleOutline,
  DataUsage,
  FilterListOutlined,
  Gavel,
  InsertDriveFileOutlined,
  KeyboardArrowDown,
  MoreVertOutlined,
  Numbers,
  PeopleAltOutlined,
  PersonOutline,
  Search,
  StickyNote2Outlined
} from '@mui/icons-material'
import { Breadcrumbs, Tab, Tabs, Tooltip } from '@mui/material'
import { staticChats } from 'data/chats'
import { staticNotes } from 'data/notes'
import ListChat from 'components/organisms/users/chat/ListChat'
import ListDocument from 'components/organisms/users/document/ListDocument'
import { staticDocuments } from 'data/documents'
import { IcSplate } from 'assets/icons'
import CustomButton from 'components/atoms/CustomButton'
import ChatFilterModal from 'components/organisms/users/chat/ChatFilterModal'
import { INITIAL_CHAT_FILTER } from 'constant'
import { showSuccessMessage } from 'utils/showMessage'
import RenameModal from 'components/organisms/users/RenameModal'
import NotesContainer from 'components/organisms/users/cases/NotesContainer'
import { useOutletContext } from 'react-router-dom'

const CasesPage = () => {
  const [modalCase, setModalCase] = useState({
    show: false,
    type: 'add' // add or edit
  })

  const [layoutInfo] = useOutletContext()
  const [infoHeaderLayout, setInfoHeaderLayout] = useState(0)
  const infoHeaderRef = useRef()

  const [seeMore, setSeeMore] = useState(false)
  const [tabTable, setTabTable] = useState(0)
  const [tabNote, setTabNote] = useState(0)
  const [filterChatModal, setFilterChatModal] = useState(INITIAL_CHAT_FILTER)
  const [renameChatModal, setRenameChatModal] = useState({ show: false })

  function openFilterChatModal() {
    setFilterChatModal((prev) => ({ ...prev, show: true }))
  }
  function closeFilterChatModal() {
    setFilterChatModal((prev) => ({ ...prev, show: false }))
  }
  function handleSubmitFilter(data) {
    setFilterChatModal((prev) => ({ ...prev, data: data }))
  }
  function openRenameChatModal() {
    setRenameChatModal((prev) => ({ ...prev, show: true }))
  }
  function closeRenameChatModal() {
    setRenameChatModal((prev) => ({ ...prev, show: false }))
  }
  function handleSubmitRenameChat() {
    showSuccessMessage('Chat renamed')
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    if (infoHeaderRef?.current?.clientHeight !== infoHeaderLayout) {
      setInfoHeaderLayout(infoHeaderRef?.current?.clientHeight ?? 0)
    }
  }, [infoHeaderRef?.current !== null, seeMore])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const optionsPopover = [
    {
      title: 'Edit',
      // callback: onEdit,
      role: ['admin', 'editor']
    },
    {
      title: 'Delete case',
      // callback: onDelete,
      role: ['admin']
    },
    {
      title: 'Export notes',
      // callback: onExport,
      role: ['admin', 'editor']
    }
  ]

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {children}
      </div>
    )
  }

  return (
    <div className="grid grid-cols-1" style={{ height: 200 }}>
      <div className="grid grid-cols-12 gap-4">
        <div className="w-full col-span-9 cursor-pointer rounded-3xl border border-solid border-[#E3E3E8] bg-white">
          <div ref={infoHeaderRef}>
            <div className="flex justify-between px-6 items-end border-solid border-t-0 border-x-0 border-[#E3E3E8] py-4 mb-6">
              <div>
                <Breadcrumbs>
                  <Typography.Text className="text-black font-normal text-[14px]">
                    Cases
                  </Typography.Text>
                </Breadcrumbs>
                <Typography.Text className="text-black font-semibold text-[30px]">
                  Tumbarello
                </Typography.Text>
              </div>
              <div className="flex items-center">
                <Typography.Text className="text-black font-normal text-[14px]">
                  Edited 12/21/23
                </Typography.Text>
                <Popover
                  content={
                    <div className="w-[128px] h-full flex flex-col text-black">
                      {optionsPopover.map(
                        (option, index) =>
                          option.role.includes('admin') && (
                            <div
                              key={index}
                              // onClick={() => {
                              //   handleClickMenu(option.callback, row)
                              // }}
                              className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                              {option.title}
                            </div>
                          )
                      )}
                    </div>
                  }
                  trigger="click"
                  placement="bottomRight"
                  arrow={false}>
                  <MoreVertOutlined
                    fontSize="small"
                    className={`cursor-pointer hover:text-[#0049EE] ml-4`}
                  />
                </Popover>
              </div>
            </div>
            <div className="flex gap-x-2 px-6">
              <div className="flex-1">
                <div className="flex items-start mb-4">
                  <div className="flex-1 flex items-center">
                    <DataUsage fontSize="small" className={`cursor-pointer text-[#828282] mr-2`} />
                    <Typography.Text className="text-[#828282] font-normal">Status</Typography.Text>
                  </div>
                  <div className="flex-[2_2_0%] flex items-center">
                    <ConfigProvider
                      theme={{
                        components: {
                          Select: {
                            colorBorder: '#EDEDEF',
                            borderRadius: 60,
                            paddingContentVertical: 0,
                            padding: 0,
                            paddingContentVerticalLG: 0,
                            paddingContentVerticalSM: 0,
                            paddingMD: 0,
                            controlHeight: 28,
                            controlItemBgActive: '#F5F5F5'
                          }
                        }
                      }}>
                      <Select
                        className="status-case-page"
                        defaultValue="preliminary inquiries"
                        popupClassName="status-case-page-popup"
                        suffixIcon={<KeyboardArrowDown fontSize="small" className="text-black" />}
                        style={{
                          width: 200
                        }}
                        options={[
                          {
                            value: 'preliminary inquiries',
                            label: (
                              <div className="flex items-center">
                                <div className="w-2 h-2 rounded-full mr-2 bg-[#B700ED]" />
                                <span className="text-black text-[14px] font-semibold">
                                  Preliminary inquiries
                                </span>
                              </div>
                            )
                          },
                          {
                            value: 'Judgment',
                            label: (
                              <div className="flex items-center">
                                <div className="w-2 h-2 rounded-full mr-2 bg-[#EDA700]" />
                                <span className="text-black text-[14px] font-semibold">
                                  Judgment
                                </span>
                              </div>
                            )
                          },
                          {
                            value: 'Preventivo',
                            label: (
                              <div className="flex items-center">
                                <div className="w-2 h-2 rounded-full mr-2 bg-[#ED6D00]" />
                                <span className="text-black text-[14px] font-semibold">
                                  Preventivo
                                </span>
                              </div>
                            )
                          },
                          {
                            value: 'Misure cautelari',
                            label: (
                              <div className="flex items-center">
                                <div className="w-2 h-2 rounded-full mr-2 bg-[#0049EE]" />
                                <span className="text-black text-[14px] font-semibold">
                                  Misure cautelari
                                </span>
                              </div>
                            )
                          },
                          {
                            value: 'Riesame',
                            label: (
                              <div className="flex items-center">
                                <div className="w-2 h-2 rounded-full mr-2 bg-[#00A2ED]" />
                                <span className="text-black text-[14px] font-semibold">
                                  Riesame
                                </span>
                              </div>
                            )
                          },
                          {
                            value: 'Archived',
                            label: (
                              <div className="flex items-center">
                                <div className="w-2 h-2 rounded-full mr-2 bg-[#828282]" />
                                <span className="text-black text-[14px] font-semibold">
                                  Archived
                                </span>
                              </div>
                            )
                          }
                        ]}
                      />
                    </ConfigProvider>
                  </div>
                </div>

                <div className="flex items-start mb-4">
                  <div className="flex-1 flex items-center">
                    <Balance fontSize="small" className={`cursor-pointer text-[#828282] mr-2`} />
                    <Typography.Text className="text-[#828282] font-normal">
                      Regulation
                    </Typography.Text>
                  </div>
                  <div className="flex-[2_2_0%] flex items-center">
                    <Typography.Text className="text-black font-semibold">Criminal</Typography.Text>
                  </div>
                </div>

                {seeMore && (
                  <div className="flex items-start mb-4">
                    <div className="flex-1 flex items-center">
                      <CalendarToday
                        fontSize="small"
                        className={`cursor-pointer text-[#828282] mr-2`}
                      />
                      <Typography.Text className="text-[#828282] font-normal">
                        Starting Date
                      </Typography.Text>
                    </div>
                    <div className="flex-[2_2_0%] flex items-center">
                      <Typography.Text className="text-black font-semibold">
                        02/07/2022
                      </Typography.Text>
                    </div>
                  </div>
                )}

                {seeMore && (
                  <div className="flex items-start mb-4">
                    <div className="flex-1 flex items-center">
                      <Numbers fontSize="small" className={`cursor-pointer text-[#828282] mr-2`} />
                      <Typography.Text className="text-[#828282] font-normal">
                        Register No.
                      </Typography.Text>
                    </div>
                    <div className="flex-[2_2_0%] flex items-center">
                      <Typography.Text className="text-black font-semibold">
                        0245GH789, 0245GH789, 0245GH789, 0245GH789, 0245GH789, 0245GH789, 0245GH789,
                        0245GH789
                      </Typography.Text>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-1">
                <div className="flex items-start mb-4">
                  <div className="flex-1 flex items-center">
                    <PersonOutline
                      fontSize="small"
                      className={`cursor-pointer text-[#828282] mr-2`}
                    />
                    <Typography.Text className="text-[#828282] font-normal">Client</Typography.Text>
                  </div>
                  <div className={`flex-[2_2_0%] ${!seeMore ? 'flex items-center' : ''}`}>
                    <Typography.Text className="text-black font-semibold">
                      Francesco Tumbarello (Pino)
                    </Typography.Text>
                    {seeMore && (
                      <Typography.Text className="text-black font-semibold">
                        , Pasquale Cantona, Raffeaello Storti, Giuseppe Asdrubali (il gozzo)
                      </Typography.Text>
                    )}
                    {!seeMore && (
                      <Typography.Text className="text-black font-semibold mx-1">
                        {' '}
                        and{' '}
                      </Typography.Text>
                    )}
                    {!seeMore && (
                      <Tooltip
                        arrow
                        title={
                          <div>
                            <div>Francesco Tumbarello (Pino),</div>
                            <div>Pasquale Cantona, Raffeaello Storti, </div>
                            <div>Giuseppe Asdrubali (il gozzo)</div>
                          </div>
                        }>
                        <Typography.Text className="text-[#0049EE] font-semibold">
                          {' '}
                          2 others
                        </Typography.Text>
                      </Tooltip>
                    )}
                  </div>
                </div>

                <div className="flex items-start mb-4">
                  <div className="flex-1 flex items-center">
                    <PeopleAltOutlined
                      fontSize="small"
                      className={`cursor-pointer text-[#828282] mr-2`}
                    />
                    <Typography.Text className="text-[#828282] font-normal">
                      Counterpart
                    </Typography.Text>
                  </div>
                  <div className="flex-[2_2_0%] flex items-center">
                    <Typography.Text className="text-black font-semibold">
                      Mario Gartullo MT
                    </Typography.Text>
                  </div>
                </div>

                {seeMore && (
                  <div className="flex items-start mb-4">
                    <div className="flex-1 flex items-center">
                      <Gavel fontSize="small" className={`cursor-pointer text-[#828282] mr-2`} />
                      <Typography.Text className="text-[#828282] font-normal">
                        Judge/Court
                      </Typography.Text>
                    </div>
                    <div className="flex-[2_2_0%] flex items-center">
                      <Typography.Text className="text-black font-semibold">
                        Dott.ssa Maria Peppini
                      </Typography.Text>
                    </div>
                  </div>
                )}

                {seeMore && (
                  <div className="flex items-start mb-4">
                    <div className="flex-1 flex items-center">
                      <AccountCircle
                        fontSize="small"
                        className={`cursor-pointer text-[#828282] mr-2`}
                      />
                      <Typography.Text className="text-[#828282] font-normal">
                        Staff
                      </Typography.Text>
                    </div>
                    <div className="flex-[2_2_0%] relative">
                      <div className="flex">
                        <Tooltip arrow className="left-[calc(18px*3)] absolute" title={'asdas'}>
                          <div className="w-6 h-6 rounded-full bg-gray-700" />
                        </Tooltip>
                        <Tooltip arrow className="left-[calc(18px*2)] absolute" title={'asdas'}>
                          <div className="w-6 h-6 rounded-full bg-blue-700" />
                        </Tooltip>
                        <Tooltip arrow className="left-[calc(18px*1)] absolute" title={'asdas'}>
                          <div className="w-6 h-6 rounded-full bg-green-700" />
                        </Tooltip>
                        <Tooltip arrow className="left-[calc(18px*0)] absolute" title={'asdas'}>
                          <div className="w-6 h-6 rounded-full bg-red-700" />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center pl-6">
              <Typography.Text
                className="text-[#828282] font-normal mr-4 hover:pointer"
                onClick={() => setSeeMore((prevState) => !prevState)}>
                {seeMore ? 'See Less' : 'See More'}
              </Typography.Text>
              <div className="h-[0.6px] flex-1 bg-[#828282]" />
            </div>
          </div>

          <div className="px-6 mt-4">
            <div className="flex items-center justify-between mb-2">
              <Tabs
                textColor="inherit"
                sx={{ minHeight: 0 }}
                TabIndicatorProps={{
                  style: { borderRadius: 20, height: 5, backgroundColor: '#0049EE' }
                }}
                value={tabTable}
                onChange={(_, newValue) => {
                  setTabTable(newValue)
                }}>
                <Tab
                  disableRipple
                  fullWidth={false}
                  sx={{
                    alignItems: 'start',
                    minWidth: 0,
                    fontSize: 24,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    textTransform: 'none',
                    marginRight: 2,
                    padding: 0
                  }}
                  label="Chats"
                  value={0}
                />
                <Tab
                  disableRipple
                  fullWidth={false}
                  sx={{
                    alignItems: 'start',
                    minWidth: 0,
                    fontSize: 24,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    textTransform: 'none',
                    padding: 0
                  }}
                  label="Documents"
                  value={1}
                />
              </Tabs>
              <div className="flex items-center">
                <Search className={`cursor-pointer hover:text-[#0049EE]`} />
                <div onClick={openFilterChatModal}>
                  <FilterListOutlined className={`cursor-pointer hover:text-[#0049EE] mx-4`} />
                </div>
                <CustomButton>
                  <div className="font-normal flex items-center">
                    New Chat
                    <Add className="ml-1" />
                  </div>
                </CustomButton>
              </div>
            </div>
            <CustomTabPanel value={tabTable} index={0}>
              <ListChat
                height={layoutInfo - infoHeaderLayout}
                data={staticChats}
                onRename={openRenameChatModal}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabTable} index={1}>
              <ListDocument height={layoutInfo - infoHeaderLayout} data={staticDocuments} />
            </CustomTabPanel>
          </div>
        </div>
        <NotesContainer
          setTabNote={setTabNote}
          tabNote={tabNote}
          data={staticNotes}
          height={layoutInfo}
        />
      </div>
      {filterChatModal.show && (
        <ChatFilterModal
          show={filterChatModal}
          data={filterChatModal.data}
          onClose={closeFilterChatModal}
          onSubmit={handleSubmitFilter}
        />
      )}
      {renameChatModal.show && (
        <RenameModal
          title={'Rename Chat'}
          onClose={closeRenameChatModal}
          show={renameChatModal.show}
          onSubmit={handleSubmitRenameChat}
        />
      )}
    </div>
  )
}

export default CasesPage
