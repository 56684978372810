import {
  ArrowForward,
  ChatBubbleOutline,
  InsertDriveFileOutlined,
  MoreVertOutlined,
  StickyNote2Outlined
} from '@mui/icons-material'
import { TablePagination, Tooltip } from '@mui/material'
import { Popover } from 'antd'
import { IcFolderList } from 'assets/icons'
import Table from 'components/atoms/Table'
import { getStatusColor } from 'lib/getStatusColor'
import React from 'react'

const ListCases = ({ data = [], onEdit = () => {}, onExport = () => {}, onDelete = () => {} }) => {
  const optionsPopover = [
    {
      title: 'Edit',
      callback: onEdit,
      role: ['admin', 'editor']
    },
    {
      title: 'Delete case',
      callback: onDelete,
      role: ['admin']
    },
    {
      title: 'Export notes',
      callback: onExport,
      role: ['admin', 'editor']
    }
  ]
  function handleClickMenu(callback, row) {
    return callback(row)
  }
  const columns = [
    {
      name: 'Case name',
      cell: (row) => (
        <div className="flex gap-2 items-center">
          <IcFolderList statusColor={getStatusColor(row?.status)} />
          <Tooltip title={row?.caseName}>
            <span className="line-clamp-1">{row?.caseName}</span>
          </Tooltip>
        </div>
      ),
      selector: (row) => row?.caseName,
      sortable: true
    },
    {
      name: 'Status',
      selector: (row) => row?.status,
      cell: (row) => (
        <div>
          <Tooltip title={row?.status}>
            <span className="line-clamp-1">{row?.status}</span>
          </Tooltip>
        </div>
      ),
      sortable: true
    },
    {
      name: 'Last change',
      selector: (row) => row?.updatedAt,
      sortable: true
    },
    {
      name: 'Opened chats',
      cell: (row) => (
        <div className="flex gap-2 items-center">
          {row?.totalChat} <ChatBubbleOutline className="!w-4 !h-[18px] !text-[#828282]" />
        </div>
      ),
      selector: (row) => row?.totalChat,
      sortable: true
    },
    {
      name: 'Documents',
      cell: (row) => (
        <div className="flex gap-2 items-center">
          {row?.totalDocument}{' '}
          <InsertDriveFileOutlined className="!w-4 !h-[18px] !text-[#828282]" />
        </div>
      ),
      selector: (row) => row?.totalDocument,
      sortable: true
    },
    {
      name: 'Notes',
      cell: (row) => (
        <div className="flex gap-2 items-center">
          {row?.totalNote} <StickyNote2Outlined className="!w-4 !h-[18px] !text-[#828282]" />{' '}
        </div>
      ),
      selector: (row) => row?.totalNote,
      sortable: true
    },
    {
      name: 'Regulation',
      selector: (row) => row?.regulation,
      sortable: true
    },
    {
      name: '',
      cell: (row) => (
        <div className="flex justify-center items-center">
          <ArrowForward className="!text-[#595961] cursor-pointer" />
        </div>
      ),
      width: '100px',
      sortable: false
    },
    {
      name: '',
      cell: (row) => (
        <Popover
          content={
            <div className="w-[158px] h-full flex flex-col text-black">
              {optionsPopover.map(
                (option, index) =>
                  option.role.includes('admin') && (
                    <div
                      key={index}
                      onClick={() => {
                        handleClickMenu(option.callback, row)
                      }}
                      className="px-2 py-1 cursor-pointer hover:bg-[#0000000A] text-black rounded text-sm font-normal">
                      {option.title}
                    </div>
                  )
              )}
            </div>
          }
          trigger="click"
          placement="leftTop"
          arrow={false}>
          <MoreVertOutlined className={`cursor-pointer hover:text-[#0049EE]`} />
        </Popover>
      ),
      width: '100px',
      sortable: false
    }
  ]

  return (
    <div className="h-full w-full flex flex-col justify-between">
      <div className="h-[calc(100vh-170px)] w-full overflow-y-auto pr-6">
        <Table hidePagination columns={columns} data={data} />
      </div>
      <div className="pr-6">
        <TablePagination
          component="div"
          count={9}
          rowsPerPageOptions={[9, 30, 50, 100]}
          page={0}
          onPageChange={() => {}}
          rowsPerPage={9}
          onRowsPerPageChange={() => {}}
        />
      </div>
    </div>
  )
}

export default ListCases
