import { zodResolver } from '@hookform/resolvers/zod'
import CustomButton from 'components/atoms/CustomButton'
import DatePickerFields from 'components/molecules/DatePickerFields'
import FilterSidebar from 'components/molecules/FilterSidebar'
import MultipleSelectFields from 'components/molecules/MultipleSelectFields'
import SelectFields from 'components/molecules/SelectFields'
import { ROLE_ADMIN, ROLE_USER } from 'constant'
import moment from 'moment'
import React from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

const staticDate = [
  {
    label: 'Less than',
    value: 'less_than'
  },
  {
    label: 'Greater than',
    value: 'greater_than'
  },
  {
    label: 'Between',
    value: 'between'
  }
]

const schema = z.object({
  company: z
    .object({
      label: z.string().optional(),
      value: z.number().optional()
    })
    .optional(),
  role: z.string().array().optional(),
  typeDate: z.string().optional(),
  startDate: z.instanceof(moment).optional(),
  endDate: z.instanceof(moment).optional()
})

const FilterUsersModal = ({ show, onClose, onSubmit, resetFilter, companyList, filterState }) => {
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
    control,
    setError,
    watch
  } = useForm({
    defaultValues: {
      company: filterState.company || undefined,
      role: filterState.role || undefined,
      typeDate: filterState.typeDate || null,
      startDate: filterState.startDate || undefined,
      endDate: filterState.endDate || undefined
    },
    mode: 'onChange',
    resolver: zodResolver(schema)
  })
  const watchRole = watch('role')
  const watchTypeDate = watch('typeDate')

  function processForm(data) {
    let newData = data
    let valid = true
    // IF typedate not selected while startdate has value
    if (watchTypeDate == undefined && data.startDate) {
      return setError('typeDate', { message: 'Required' })
    }
    // if typedate not between but enddate has value
    if (watchTypeDate !== 'between' && getValues('endDate')) {
      newData = { ...newData, endDate: undefined }
    }
    // if typedate has value but start date n end date don't have value
    if (watchTypeDate && !data.startDate && !data.endDate) {
      newData = { ...newData, typeDate: undefined }
    }
    // if typedate value is between but enddate or start date doesn't has value
    if (watchTypeDate === 'between') {
      if (!data.startDate) {
        valid = false
        setError('startDate', { message: 'Required' })
      }
      if (!data.endDate) {
        valid = false
        setError('endDate', { message: 'Required' })
      }
    }

    if (valid) {
      onSubmit(newData)
      onClose()
    }
  }
  function resetForm() {
    reset()
    resetFilter()
    onClose()
  }

  return (
    <FilterSidebar
      show={show}
      onClose={() => {
        reset()
        onClose()
      }}>
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-4">
          <SelectFields
            labelClassName={'!text-white'}
            control={control}
            label={'Company'}
            showSearch
            placeholder={'Choose company'}
            errors={errors}
            name={'company'}
            receivedSelectedObject
            options={companyList}
          />
          <MultipleSelectFields
            labelClassName={'!text-white'}
            control={control}
            placeholder={'Choose Role'}
            label={'Role'}
            state={watchRole}
            errors={errors}
            name={'role'}
            options={[
              {
                label: 'Admin',
                value: ROLE_ADMIN
              },
              {
                label: 'User',
                value: ROLE_USER
              }
            ]}
          />
          <SelectFields
            labelClassName={'!text-white'}
            control={control}
            label={'Date'}
            placeholder={'Choose Date'}
            errors={errors}
            name={'typeDate'}
            options={staticDate}
          />

          <DatePickerFields
            labelClassName={'!text-white'}
            control={control}
            name={'startDate'}
            placeholder={'Choose Date'}
            errors={errors}
          />
          {watchTypeDate === 'between' && (
            <DatePickerFields
              labelClassName={'!text-white'}
              control={control}
              name={'endDate'}
              placeholder={'Choose Date'}
              errors={errors}
            />
          )}
        </div>
        <div className="flex gap-2 justify-end items-center">
          <CustomButton buttonColor="secondary" onClick={resetForm}>
            Remove Filter
          </CustomButton>
          <CustomButton buttonType="submit" onClick={handleSubmit(processForm)}>
            Apply
          </CustomButton>
        </div>
      </div>
    </FilterSidebar>
  )
}

export default FilterUsersModal
