import React from 'react'
import { Typography } from 'antd'
import { Box, Button } from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import ModalUser from 'components/molecules/user/ModalUser'
import SelectUserFields from 'components/molecules/user/SelectUserFields'
import TextFields from 'components/molecules/TextFields'
import DatePickerUserFields from 'components/molecules/user/DatePickerUserFields'
import { ADD_CASE, DELETE_CASE, EXPORT_CASE_NOTES } from 'constant'
import SelectCheckboxCaseFields from 'components/molecules/user/cases/SelectCheckboxCaseFields'
import CustomButton from 'components/atoms/CustomButton'
import { showSuccessMessage } from 'utils/showMessage'
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'

const schema = z.object({
  caseName: z.string().min(1, { message: 'Case name is required' }),
  regulation: z.string().min(1, { message: 'Regulation is required' }),
  clients: z.array(
    z.object({
      clientName: z.string().min(1, { message: 'Client name is required' }),
      clientLastName: z.string().min(1, { message: 'Client last name is required' }),
      clientNickname: z.string().min(1, { message: 'Client nickname is required' }),
    })
  ),
  counterparts: z.array(
    z.object({
      counterpartName: z.string().min(1, { message: 'Counterpart name is required' }),
      counterpartLastName: z.string().min(1, { message: 'Counterpart last name is required' }),
      counterpartNickname: z.string().min(1, { message: 'Counterpart nickname is required' }),
    })
  ),
  startDate: z.any(),
  status: z.string(),
  proceedingNumber: z.string(),
  judge: z.string(),
  usersWithAccess: z.array(
    z.string()
  ).min(1, { message: 'Users with access is required' })
})

const CaseModal = ({
  show,
  // data,
  type = ADD_CASE,
  onClose,
  onSubmit = () => {},
  message
}) => {
  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
    // setError
  } = useForm({
    defaultValues: {
      caseName: '',
      regulation: '',
      clients: [
        {
          clientLastName: '',
          clientNickname: '',
          clientName: '',
        }
      ],
      counterparts: [
        {
          counterpartLastName: '',
          counterpartNickname: '',
          counterpartName: '',
        }
      ],
      startDate: null,
      status: '',
      proceedingNumber: '',
      judge: '',
      usersWithAccess: []
    },
    resolver: zodResolver(schema)
  })

  const cliensField = useFieldArray({ control, name: 'clients' })
  const counterpartsField = useFieldArray({ control, name: 'counterparts' })

  function closeModal() {
    reset()
    onClose()
  }
  function processForm(data) {
    onSubmit(data)
    closeModal()
  }

  if (type === DELETE_CASE || type === EXPORT_CASE_NOTES) {
    return (
      <ModalUser
        centered={false}
        onClose={closeModal}
        show={show}
        width={444}
        titleComponent={
          <div className="font-semibold text-xl">
            {type === DELETE_CASE ? 'Delete Case' : 'Export Notes'}
          </div>
        }>
        <div className="flex flex-col justify-between gap-5">
          <Typography.Text>{message}</Typography.Text>
          <div className="flex justify-end gap-2">
            <CustomButton onClick={closeModal} buttonColor="secondary">
              Cancel
            </CustomButton>
            <CustomButton
              buttonType="submit"
              variant="contained"
              onClick={() => {
                showSuccessMessage(type === DELETE_CASE? 'Case Deleted' : "Notes Exported")
                closeModal();
              }}
            >
              {type === DELETE_CASE? 'Delete' : 'Export'}
            </CustomButton>
          </div>
        </div>
      </ModalUser>
    )
  }

  return (
    <ModalUser
      show={show}
      modalTheme={{
        fontWeightStrong: '400'
      }}
      centered={false}
      title={type === ADD_CASE ? 'New case' : 'Edit Case'}
      onClose={closeModal}>
      <Box
        noValidate
        component="form"
        onSubmit={handleSubmit(processForm)}
        className="flex flex-col w-full">
        <div className="flex gap-4 mb-4 justify-center items-center">
          <TextFields control={control} label={'Case name'} name={'caseName'} errors={errors} />
          <SelectUserFields
            control={control}
            label={'Regulation'}
            name={'regulation'}
            errors={errors}
            options={[
              { value: 'civil', label: 'Civil' },
              { value: 'criminal', label: 'Criminal' }
            ]}
          />
        </div>
        {cliensField?.fields?.map((field, index) => (
          <div className="flex gap-4 mb-4 justify-center items-center" key={`client-${field?.id}`}>
            <TextFields
              control={control}
              inArray={true}
              label={'Client Name'}
              name={`clients[${index}].clientName`}
              errors={errors?.clients?.[index]?.clientName}
            />
            <TextFields
              control={control}
              inArray={true}
              label={'Client last name'}
              name={`clients[${index}].clientLastName`}
              errors={errors?.clients?.[index]?.clientLastName}
            />
            <TextFields
              control={control}
              inArray={true}
              label={'Client nickname'}
              name={`clients[${index}].clientNickname`}
              errors={errors?.clients?.[index]?.clientNickname}
            />
            {cliensField?.fields?.length - 1 === index? (
              <AddCircleOutline
                className='text-[#595961] hover:cursor-pointer hover:text-black ml-1'
                onClick={() => {
                  cliensField.insert(index, {
                    clientLastName: '',
                    clientNickname: '',
                    clientName: '',
                  })
                }}
              />
            ) : (
              <RemoveCircleOutline
                className='text-[#595961] hover:cursor-pointer hover:text-black ml-1'
                onClick={() => {
                  cliensField.remove(index)
                }}
              />
            )}
          </div>
        ))}
        {counterpartsField?.fields?.map((field, index) => (
          <div className="flex gap-4 mb-4 justify-center items-center" key={`client-${field?.id}`}>
            <TextFields
              control={control}
              inArray={true}
              label={'Counterpart Name'}
              name={`counterparts[${index}].counterpartName`}
              errors={errors?.counterparts?.[index]?.counterpartName}
            />
            <TextFields
              control={control}
              inArray={true}
              label={'Counterpart last name'}
              name={`counterparts[${index}].counterpartLastName`}
              errors={errors?.counterparts?.[index]?.counterpartLastName}
            />
            <TextFields
              control={control}
              inArray={true}
              label={'Counterpart nickname'}
              name={`counterparts[${index}].counterpartNickname`}
              errors={errors?.counterparts?.[index]?.counterpartNickname}
            />
            {counterpartsField?.fields?.length - 1 === index ? (
              <AddCircleOutline
                className='text-[#595961] hover:cursor-pointer hover:text-black ml-1'
                onClick={() => {
                  counterpartsField.insert(index, {
                    counterpartLastName: '',
                    counterpartNickname: '',
                    counterpartName: '',
                  })
                }}
              />
            ) : (
              <RemoveCircleOutline
                className='text-[#595961] hover:cursor-pointer hover:text-black ml-1'
                onClick={() => {
                  counterpartsField.remove(index)
                }}
              />
            )}
          </div>
        ))}
        <div className="my-4">
          <Typography.Text className="font-semibold">Optional Information</Typography.Text>
        </div>
        <div className="flex gap-4 mb-4 justify-center items-center ">
          <DatePickerUserFields
            control={control}
            label={'Proceeding starting date'}
            name={'startDate'}
            errors={errors}
          />
          <SelectUserFields
            control={control}
            label={'Status'}
            name={'status'}
            errors={errors}
            options={[
              {
                value: 'preventivo',
                label: (
                  <div className="flex items-center">
                    <div className="w-2 h-2 rounded-full mr-3 bg-[#EDA700]" />
                    <span className="font-normal text-black">Preventivo</span>
                  </div>
                )
              },
              {
                value: 'preliminary inquiries',
                label: (
                  <div className="flex items-center">
                    <div className="w-2 h-2 rounded-full mr-3 bg-[#B700ED]" />
                    <span className="font-normal text-black">Preliminary inquiries</span>
                  </div>
                )
              },
              {
                value: 'precautionary measures',
                label: (
                  <div className="flex items-center">
                    <div className="w-2 h-2 rounded-full mr-3 bg-[#0049EE]" />
                    <span className="font-normal text-black">Precautionary measures</span>
                  </div>
                )
              },
              {
                value: 'Dibattimento',
                label: (
                  <div className="flex items-center">
                    <div className="w-2 h-2 rounded-full mr-3 bg-[#ED6D00]" />
                    <span className="font-normal text-black">Dibattimento</span>
                  </div>
                )
              },
              {
                value: 'Riesame',
                label: (
                  <div className="flex items-center">
                    <div className="w-2 h-2 rounded-full mr-3 bg-[#00A2ED]" />
                    <span className="font-normal text-black">Riesame</span>
                  </div>
                )
              }
            ]}
          />
        </div>
        <div className="flex gap-4 mb-4 justify-center items-center ">
          <TextFields
            control={control}
            label={'Proceeding number'}
            name={'proceedingNumber'}
            errors={errors}
          />
        </div>
        <div className="flex gap-4 mb-4 justify-center items-center ">
          <TextFields control={control} label={'Judge/Court'} name={'judge'} errors={errors} />
        </div>
        <div className="my-4">
          <Typography.Text className="font-semibold">Permission management</Typography.Text>
        </div>
        <div className="flex gap-4 mb-4 justify-center items-center ">
          <SelectCheckboxCaseFields
            control={control}
            label={'Users with access to this case'}
            name={'usersWithAccess'}
            errors={errors}
            options={[
              { value: 'user1', label: 'User 1' },
              { value: 'user2', label: 'User 2' }
            ]}
          />
        </div>
        {/* Button */}
        <div className="flex justify-end gap-2">
          <Button
            onClick={closeModal}
            type="button"
            variant="outlined"
            sx={{
              borderColor: '#888',
              fontSize: '14px',
              textTransform: 'none',
              color: '#888'
            }}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{
              // mt: 4,
              backgroundColor: '#0049EE',
              fontSize: '14px',
              textTransform: 'none'
            }}>
            Confirm
          </Button>
        </div>
      </Box>
    </ModalUser>
  )
}

export default CaseModal
