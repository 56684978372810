import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import Table from 'components/atoms/Table'
import NullComponent from 'components/molecules/NullComponent'
import AdminHeader from 'components/molecules/admin/AdminHeader'
import AddCompanyModal from 'components/organisms/admin/companies/AddCompanyModal'
import FilterCompaniesModal from 'components/organisms/admin/companies/FilterCompaniesModal'
import { showSuccessMessage } from 'utils/showMessage'
import AdminPagination from 'components/atoms/AdminPagination'
import { useSelector } from 'react-redux'
import { getCompanyList } from 'api'
import moment from 'moment'

const initialTableState = {
  total: 0,
  page: 1,
  limit: 9,
  search: '',
  sort: '-created_at'
}

const initialFilterState = {
  typeCountUsers: undefined,
  startUserNumbers: undefined,
  endUserNumbers: undefined,
  typeDate: undefined,
  startDate: undefined,
  endDate: undefined
}

const CompaniesPage = () => {
  const { state } = useLocation()
  const [filter, setFilter] = useState({ show: false })
  const [filterState, setFilterState] = useState(initialFilterState)
  const [addModal, setAddModal] = useState(state?.addNew ? state?.addNew : false)
  const [tableState, setTableState] = useState(initialTableState)
  const token = useSelector((state) => state.admin.access_token)
  const navigate = useNavigate()
  // RESET STATE ADD NEW WITHOUT TRIGGER RE-RENDER
  // state ? window.history.replaceState({}, '') : null

  const { isLoading, data, refetch } = useQuery({
    queryKey: [
      'admin-companies-list',
      tableState?.sort,
      tableState?.limit,
      tableState?.page,
      tableState?.search,
      filterState
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const res = await getCompanyList(token, filterState, tableState)
      return res
    },
    onSuccess: (res) => {
      setTableState({
        ...tableState,
        total: res?.resData?.meta?.total
      })
    }
  })

  function openAddModal() {
    setAddModal(true)
  }
  function closeAddModal() {
    setAddModal(false)
  }

  function handleAddCompany() {
    refetch()
    showSuccessMessage('Company added')
  }

  function closeFilterModal() {
    setFilter((prev) => ({ ...prev, show: false }))
  }
  function openFilterModal() {
    setFilter((prev) => ({ ...prev, show: true }))
  }

  function handleSearch(value) {
    setTableState((prevState) => ({
      ...prevState,
      search: value
    }))
  }

  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort: `${sortDirection === 'desc' ? '-' : ''}${column?.id}`,
      sort_order: sortDirection
    }))
  }

  function onChangePagination(_, page) {
    setTableState((prevState) => ({
      ...prevState,
      page: page
    }))
  }

  function handleSubmitFilter(data) {
    setFilterState(data)
  }
  function resetFilter() {
    setFilterState(initialFilterState)
  }

  const columns = [
    {
      id: 'name',
      name: 'COMPANY NAME',
      selector: (row) => row?.name,
      sortable: true
    },
    {
      id: 'email',
      name: 'EMAIL',
      selector: (row) => row?.email,
      sortable: true
    },
    {
      id: 'phone',
      name: 'TELEPHONE NUMBER',
      selector: (row) => row?.phone,
      sortable: true
    },
    {
      id: 'user_count',
      name: 'NO. USERS',
      selector: (row) => row?.user_count,
      sortable: true
    },
    {
      id: 'created_at',
      name: 'DATE',
      selector: (row) => moment(row?.created_at).format('DD/MM/YYYY'),
      sortable: true
    }
  ]

  function handleNavCompanyDetails(row) {
    navigate('/admin/companies/detail', {
      state: {
        ...row
      }
    })
  }

  return (
    <div className="flex flex-col gap-6 w-full overflow-y-auto pr-6">
      <AdminHeader
        onAdd={openAddModal}
        openFilter={openFilterModal}
        onSearch={handleSearch}
        title={'Companies'}
      />
      <div className="flex flex-col h-full justify-between pb-4">
        {((isLoading && !data) || (data?.resData?.data?.length > 0 && !isLoading)) && (
          <>
            <Table
              defaultSortFieldId={'created_at'}
              defaultSortAsc={false}
              pending={isLoading}
              sortServer
              onChangePrevPage={() => {
                if (tableState?.page > 1)
                  setTableState((prevState) => {
                    return {
                      ...prevState,
                      page: prevState?.page - 1
                    }
                  })
              }}
              onChangeNextPage={() => {
                if ((tableState?.page + 1) * tableState?.limit <= data?.resData?.meta?.total)
                  setTableState((prevState) => {
                    return {
                      ...prevState,
                      page: prevState?.page + 1
                    }
                  })
              }}
              hidePagination
              data={data?.resData?.data}
              columns={columns}
              onRowClicked={handleNavCompanyDetails}
              currentPage={tableState?.page}
              totalPerPage={tableState?.limit}
              page={tableState?.page}
              pageSize={tableState?.limit}
              totalRows={data?.resData?.meta?.total}
              onSort={handleSort}
            />
            {data && (
              <div className="flex w-full justify-end items-center">
                <AdminPagination
                  count={Math.ceil(data?.resData?.meta?.total / tableState?.limit)}
                  page={tableState?.page}
                  onChange={onChangePagination}
                  shape="rounded"
                />
              </div>
            )}
          </>
        )}
        {data?.resData?.data?.length < 1 && !isLoading && (
          <NullComponent
            title={'No company added'}
            description={'Add a company and pair users to it'}
          />
        )}
        <FilterCompaniesModal
          show={filter.show}
          onClose={closeFilterModal}
          onSubmit={handleSubmitFilter}
          resetFilter={resetFilter}
          filterState={filterState}
        />
        <AddCompanyModal onClose={closeAddModal} show={addModal} onSubmit={handleAddCompany} />
      </div>
    </div>
  )
}

export default CompaniesPage
