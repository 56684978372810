export const staticNotes = [
    {
        type: 'chat',
        typeName: 'Chat Name',
        note: 'It is interesting to note the repetition of events in the case. Follow up with the suspect.',
        createdAt: '12/10/2024'
    },
    {
        type: 'document',
        typeName: 'Document Name',
        note: 'It is interesting to note the repetition of events in the case. Follow up with the suspect.',
        createdAt: '12/10/2024'
    },
    {
        type: 'insight',
        typeName: 'Free Insight',
        note: 'It is interesting to note the repetition of events in the case. Follow up with the suspect.',
        createdAt: '12/10/2024'
    },
    {
        type: 'document',
        typeName: 'Document Name',
        note: 'It is interesting to note the repetition of events in the case. Follow up with the suspect.',
        createdAt: '12/10/2024'
    },
    {
        type: 'document',
        typeName: 'Document Name',
        note: 'It is interesting to note the repetition of events in the case. Follow up with the suspect.',
        createdAt: '12/10/2024'
    },
    {
        type: 'document',
        typeName: 'Document Name',
        note: 'It is interesting to note the repetition of events in the case. Follow up with the suspect.',
        createdAt: '12/10/2024'
    },
    {
        type: 'document',
        typeName: 'Document Name',
        note: 'It is interesting to note the repetition of events in the case. Follow up with the suspect.',
        createdAt: '12/10/2024'
    },
    {
        type: 'document',
        typeName: 'Document Name',
        note: 'It is interesting to note the repetition of events in the case. Follow up with the suspect.',
        createdAt: '12/10/2024'
    },
    {
        type: 'document',
        typeName: 'Document Name',
        note: 'It is interesting to note the repetition of events in the case. Follow up with the suspect.',
        createdAt: '12/10/2024'
    },
    {
        type: 'document',
        typeName: 'Document Name',
        note: 'It is interesting to note the repetition of events in the case. Follow up with the suspect.',
        createdAt: '12/10/2024'
    },
    {
        type: 'document',
        typeName: 'New Document Name',
        note: 'It is interesting to note the repetition of events in the case. Follow up with the suspect.',
        createdAt: '12/10/2024'
    },
]