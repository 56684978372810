import React from 'react'

const IcPrivacyPolicy = ({ className, size = 20 }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.125 11.1719C8.125 10.1397 9.00045 9.375 10 9.375C10.9996 9.375 11.875 10.1397 11.875 11.1719V11.875C11.875 12.2202 11.5952 12.5 11.25 12.5C10.9048 12.5 10.625 12.2202 10.625 11.875V11.1719C10.625 10.9048 10.3864 10.625 10 10.625C9.61362 10.625 9.375 10.9048 9.375 11.1719V11.875C9.375 12.2202 9.09518 12.5 8.75 12.5C8.40482 12.5 8.125 12.2202 8.125 11.875V11.1719Z"
      fill="currentColor"
    />
    <path
      d="M11.875 16.25H8.125C7.79348 16.25 7.47554 16.1183 7.24112 15.8839C7.0067 15.6495 6.875 15.3315 6.875 15V13.125C6.875 12.7935 7.0067 12.4755 7.24112 12.2411C7.47554 12.0067 7.79348 11.875 8.125 11.875H11.875C12.2065 11.875 12.5245 12.0067 12.7589 12.2411C12.9933 12.4755 13.125 12.7935 13.125 13.125V15C13.125 15.3315 12.9933 15.6495 12.7589 15.8839C12.5245 16.1183 12.2065 16.25 11.875 16.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.625 2.5C5.29348 2.5 4.97554 2.6317 4.74112 2.86612C4.5067 3.10054 4.375 3.41848 4.375 3.75V16.25C4.375 16.5815 4.5067 16.8995 4.74112 17.1339C4.97554 17.3683 5.29348 17.5 5.625 17.5H14.375C14.7065 17.5 15.0245 17.3683 15.2589 17.1339C15.4933 16.8995 15.625 16.5815 15.625 16.25V8.64267C15.625 8.64264 15.625 8.6427 15.625 8.64267C15.625 8.477 15.5591 8.31804 15.442 8.20088L9.92412 2.68301C9.92411 2.68299 9.92414 2.68302 9.92412 2.68301C9.80697 2.56591 9.64807 2.50005 9.48242 2.5C9.48239 2.5 9.48245 2.5 9.48242 2.5H5.625ZM3.85723 1.98223C4.32607 1.51339 4.96196 1.25 5.625 1.25H9.48242C9.97953 1.25008 10.4564 1.44755 10.8079 1.79902L16.3259 7.31704C16.6774 7.66859 16.8749 8.14538 16.875 8.64248V16.25C16.875 16.913 16.6116 17.5489 16.1428 18.0178C15.6739 18.4866 15.038 18.75 14.375 18.75H5.625C4.96196 18.75 4.32607 18.4866 3.85723 18.0178C3.38839 17.5489 3.125 16.913 3.125 16.25V3.75C3.125 3.08696 3.38839 2.45107 3.85723 1.98223Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.3623C10.3452 1.3623 10.625 1.64213 10.625 1.9873V6.8748C10.625 7.04056 10.6908 7.19954 10.8081 7.31675C10.9253 7.43396 11.0842 7.4998 11.25 7.4998H16.1375C16.4827 7.4998 16.7625 7.77963 16.7625 8.1248C16.7625 8.46998 16.4827 8.7498 16.1375 8.7498H11.25C10.7527 8.7498 10.2758 8.55226 9.92418 8.20063C9.57254 7.849 9.375 7.37209 9.375 6.8748V1.9873C9.375 1.64213 9.65482 1.3623 10 1.3623Z"
      fill="currentColor"
    />
  </svg>
)

export default IcPrivacyPolicy
