import React from 'react'

const IcClose = ({ size = 17, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 17 17" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.25 4.25L13.2984 13.2984" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.25 13.2988L13.2984 4.25044" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default IcClose;
