import { Tooltip } from '@mui/material'
import { getCompanyList, getUserList } from 'api'
import AdminPagination from 'components/atoms/AdminPagination'
import Table from 'components/atoms/Table'
import AdminHeader from 'components/molecules/admin/AdminHeader'
import FilterUsersModal from 'components/organisms/admin/FilterUsersModal'
import AddUserModal from 'components/organisms/admin/users/AddUserModal'
import useAuth from 'hooks/useAuth'
import { ceil } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { errorObj } from 'utils/handleError'
import { showErrorMessage } from 'utils/showMessage'

const initialUsersTableState = {
  sort: '-created_at',
  sort_order: 'desc',
  search: ''
}
const initialFilterUserState = {
  company: undefined,
  role: undefined,
  typeDate: undefined,
  startDate: undefined,
  endDate: undefined
}

const UsersPage = () => {
  const { getAccessToken } = useAuth()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [filterModal, setFilterModal] = useState(false)
  const [companyList, setCompanyList] = useState({ data: [], meta: {} })
  const [usersList, setUsersList] = useState({ data: [], meta: {} })
  const [tableState, setTableState] = useState(initialUsersTableState)
  const [filterState, setFilterState] = useState(initialFilterUserState)
  // Add User
  const [addModal, setAddModal] = useState(false)

  const { refetch, isFetching } = useQuery({
    queryKey: [filterState, currentPage, tableState],
    queryFn: () => getUserList(getAccessToken(), currentPage, filterState, tableState),
    onSuccess: (data) => {
      setUsersList(data.resData)
    },
    onError: (err) => {
      const objErr = errorObj(err)
      showErrorMessage('Failed fetch user list, Please refresh the page')
    }
  })
  useQuery({
    queryKey: ['getCompanyQuery'],
    queryFn: () => getCompanyList(getAccessToken()),
    onSuccess: (data) => {
      const newCompanyListOptions = data.resData.data.map((company) => ({
        label: company?.name,
        value: company?.id,
        ...company
      }))
      setCompanyList(newCompanyListOptions)
    },
    onError: (err) => {
      const objErr = errorObj(err)
      showErrorMessage('Failed fetch company list options,Please refresh the page')
    }
  })

  function navigateToAddCompany() {
    navigate('/admin/companies', { state: { addNew: true } })
  }

  function openAddModal() {
    setAddModal(true)
  }
  function closeAddModal() {
    setAddModal(false)
  }

  function handleSearch(value) {
    setTableState((prev) => ({ ...prev, search: value }))
    setCurrentPage(1)
  }

  function closeFilterModal() {
    setFilterModal(false)
  }
  function openFilterModal() {
    setFilterModal(true)
  }

  function handleSubmitFilter(data) {
    setFilterState(data)
    setCurrentPage(1)
  }
  function resetFilter() {
    setFilterState({ ...initialFilterUserState })
  }

  function handleNavUserDetails(data) {
    navigate(`/admin/users/${data?.id}`)
  }

  function onChangePagination(_, page) {
    setCurrentPage(page)
  }
  const handleSort = (column, sortDirection) => {
    setTableState((prevState) => ({
      ...prevState,
      sort: `${sortDirection === 'desc' ? '-' : ''}${column?.id}`,
      sort_order: sortDirection
    }))
  }

  const columns = [
    {
      id: 'name',
      name: 'NAME',
      cell: (row) => (
        <div>
          <Tooltip title={`${row?.name} ${row?.surname}`}>
            <span className="line-clamp-1">
              {row?.name} {row?.surname}
            </span>
          </Tooltip>
        </div>
      ),
      selector: (row) => row?.name,
      sortable: true
    },
    {
      id: 'email',
      name: 'EMAIL',
      selector: (row) => row?.email,
      sortable: true
    },
    {
      id: 'phone',
      name: 'TELEPHONE NUMBER',
      selector: (row) => row?.phone,
      sortable: true
    },
    {
      id: 'company_name',
      name: 'COMPANY',
      selector: (row) => row?.company?.name,
      sortable: true
    },
    {
      id: 'role',
      name: 'ROLE',
      selector: (row) => row?.role?.role,
      sortable: true
    },
    {
      id: 'created_at',
      name: 'DATE',
      selector: (row) => moment(row?.created_at).format('DD/MM/YYYY'),
      sortable: true
    }
  ]
  return (
    <div className="flex flex-col gap-6 w-full overflow-y-auto pr-6">
      <AdminHeader
        onAdd={openAddModal}
        onSearch={handleSearch}
        title={'Users'}
        openFilter={openFilterModal}
      />
      <div className="flex flex-col h-full justify-between pb-4">
        <>
          <Table
            pending={isFetching}
            defaultSortFieldId={'created_at'}
            defaultSortAsc={false}
            hidePagination
            sortServer
            data={usersList.data}
            columns={columns}
            onRowClicked={handleNavUserDetails}
            onSort={handleSort}
          />
          {!isFetching && (
            <div className="flex w-full justify-end items-center">
              <AdminPagination
                count={ceil(usersList.meta?.total / usersList.meta?.limit)}
                page={currentPage}
                onChange={onChangePagination}
                shape="rounded"
              />
            </div>
          )}
        </>
      </div>

      <FilterUsersModal
        show={filterModal}
        onSubmit={handleSubmitFilter}
        onClose={closeFilterModal}
        companyList={companyList}
        resetFilter={resetFilter}
        filterState={filterState}
      />
      <AddUserModal
        refetchUserList={refetch}
        onClose={closeAddModal}
        navigateToAddCompany={navigateToAddCompany}
        companyList={companyList}
        show={addModal}
      />
    </div>
  )
}

export default UsersPage
