import CustomButton from 'components/atoms/CustomButton'
import ModalUser from 'components/molecules/user/ModalUser'
import React from 'react'

import parse from 'html-react-parser'
const styles = {
  title: 'text-black font-normal text-base',
  sub_title: 'text-base font-semibold',
  paragraph: 'list-disc list-inside'
}
const TCModal = ({ show, onClose, onSubmit, onLogout, isLoading, body = [] }) => {
  return (
    <ModalUser title={'Terms and conditions'} show={show} onClose={onClose}>
      <div className="h-96 overflow-y-auto flex flex-col gap-4">
        {body?.length > 0
          ? body.map(({ key, value }) => {
              switch (key) {
                case 'title':
                case 'sub_title':
                  return <span className={styles[key]}>{value}</span>
                case 'paragraph':
                  return parse(value)
              }
            })
          : null}
        {/* <div className="text-base font-normal flex gap-2 flex-col">
          <p className="text-base font-semibold">1. Basic use requirements</p>
          <p>The information collected may be used to:</p>
          <ul className="list-disc list-inside">
            <li>Provide, maintain, and improve our platform.</li>
            <li>Manage your account and provide you with customer support.</li>
            <li>Perform internal analysis and research to improve the technology.</li>
            <li>Send updates, security alerts, and administrative messages.</li>
            <li>
              Communicate about products, services, offers, promotions and events offered by us and
              our partners.
            </li>
          </ul>
        </div>
        <div className="text-base font-normal flex gap-2 flex-col">
          <p className="text-base font-semibold ">2. Your agreement</p>
          <p>You have the right to:</p>
          <ul className="list-disc list-inside">
            <li>Access, correct or delete your personal information.</li>
            <li>Object to the processing of your information.</li>
            <li>
              Request restriction of the processing of your information. Request data portability.
            </li>
          </ul>
        </div> */}
      </div>

      <div className="flex justify-end items-center gap-4">
        <CustomButton buttonColor="secondary" disabled={isLoading} onClick={onLogout}>
          Logout
        </CustomButton>
        <CustomButton onClick={!isLoading ? onSubmit : () => {}} isLoading={isLoading}>
          Accept
        </CustomButton>
      </div>
    </ModalUser>
  )
}

export default TCModal
