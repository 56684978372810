export const staticChats = [
    {
        name: 'Arresto Tumbarello 1',
        status: 'Preliminary inquiries',
        createdAt: '02/07/2023',
        totalChat: 28,
        totalDocument: 10,
        totalNote: 10,
    },
    {
        name: 'Arresto Tumbarello 2',
        status: 'Preliminary inquiries',
        createdAt: '02/07/2023',
        totalChat: 28,
        totalDocument: 10,
        totalNote: 10,
    },
    {
        name: 'Arresto Tumbarello 3',
        status: 'Preliminary inquiries',
        createdAt: '02/07/2023',
        totalChat: 28,
        totalDocument: 10,
        totalNote: 10,
    },
    {
        name: 'Arresto Tumbarello 4',
        status: 'Preliminary inquiries',
        createdAt: '02/07/2023',
        totalChat: 28,
        totalDocument: 10,
        totalNote: 10,
    },
    {
        name: 'Arresto Tumbarello 5',
        status: 'Preliminary inquiries',
        createdAt: '02/07/2023',
        totalChat: 28,
        totalDocument: 10,
        totalNote: 10,
    },
    {
        name: 'Arresto Tumbarello 6',
        status: 'Preliminary inquiries',
        createdAt: '02/07/2023',
        totalChat: 28,
        totalDocument: 10,
        totalNote: 10,
    },
    {
        name: 'Arresto Tumbarello 7',
        status: 'Preliminary inquiries',
        createdAt: '02/07/2023',
        totalChat: 28,
        totalDocument: 10,
        totalNote: 10,
    },
    {
        name: 'Arresto Tumbarello 8',
        status: 'Preliminary inquiries',
        createdAt: '02/07/2023',
        totalChat: 28,
        totalDocument: 10,
        totalNote: 10,
    },
    {
        name: 'Arresto Tumbarello 9',
        status: 'Preliminary inquiries',
        createdAt: '02/07/2023',
        totalChat: 28,
        totalDocument: 10,
        totalNote: 10,
    },
]
