import { Typography } from "antd"
import React from "react"

const NullComponent = ({ title, description }) => {
	return (
		<div className="flex flex-col gap-2">
			<Typography.Title className="!text-[#FFFFFFCC] !font-manrope !text-2xl">
				{title}
			</Typography.Title>
			<Typography.Paragraph className="!text-[#FFFFFFCC] !font-manrope !text-xl">
				{description}
			</Typography.Paragraph>
		</div>
	)
}

export default NullComponent
