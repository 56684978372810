import React from 'react'

const IcSplate = ({ className, size = 20 }) => (
    <svg className={className} width={size} height={size} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.03333 6.63281L1 9.66614" stroke="#828282" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.66614 1L6.63281 4.03333" stroke="#828282" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.63281 6.63281L9.66614 9.66614" stroke="#828282" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 1L4.03333 4.03333" stroke="#828282" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.07031 1H9.67031V3.59999" stroke="#828282" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.59999 9.6664H1V7.06641" stroke="#828282" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 3.59999V1H3.59999" stroke="#828282" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.67031 7.06641V9.6664H7.07031" stroke="#828282" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export default IcSplate