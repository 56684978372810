import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    gpt_key: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    municipality: '',
    pec_address: '',
    province: '',
    sdi_receiver_code: '',
    tax_code: '',
    vat_number: '',
    zip_code: '',
    country_id: ''
}

const adminCompanySlice = createSlice({
  name: 'adminCompany',
  initialState: initialState,
  reducers: {
    setStep1(state, action) {
      state.gpt_key = action.payload.gpt_key
    },
    setStep2(state, action) {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
    },
    setStep3(state, action) {
      state.address = action.payload.address
      state.municipality = action.payload.municipality
      state.pec_address = action.payload.pec_address
      state.province = action.payload.province
      state.sdi_receiver_code = action.payload.sdi_receiver_code
      state.tax_code = action.payload.tax_code
      state.vat_number = action.payload.vat_number
      state.zip_code = action.payload.zip_code
      state.country_id = action.payload.country_id
    },
    resetAll(state) {
      Object.assign(state, initialState)
    }
  }
})

export const adminCompanyActions = adminCompanySlice.actions

export const adminCompanyReducer = adminCompanySlice.reducer
