import { apiGet } from 'api/api-get'
import { apiPut } from 'api/api-put'
import { urls } from 'api/api-url'

export const putUpdateEmail = (token, email) => {
  return apiPut({
    url: urls.profile.profile,
    token: token,
    data: {
      email: email
    }
  })
}
export const putChangePassword = (token, data) => {
  return apiPut({
    url: urls.auth.changePassword,
    token: token,
    data: data
  })
}

export const getUserProfile = (token) => {
  return apiGet({
    token: token
  })
}
