import React from 'react'

const IcChevronDown = ({ className, size = 20 }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.59846 4.56721C2.85474 4.31093 3.27026 4.31093 3.52654 4.56721L7 8.04067L10.4735 4.56721C10.7297 4.31093 11.1453 4.31093 11.4015 4.56721C11.6578 4.82349 11.6578 5.23901 11.4015 5.49529L7.46404 9.43279C7.20776 9.68907 6.79224 9.68907 6.53596 9.43279L2.59846 5.49529C2.34218 5.23901 2.34218 4.82349 2.59846 4.56721Z"
      fill="currentColor"
      fillOpacity="1"
    />
  </svg>
)

export default IcChevronDown
