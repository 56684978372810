import { getTC, putAcceptTC } from 'api'
import NullCaseComponent from 'components/molecules/user/cases/NullCaseComponent'
import TCModal from 'components/organisms/users/TCModal'
import CaseHeader from 'components/organisms/users/cases/CaseHeader'
import CaseModal from 'components/organisms/users/cases/CaseModal'
import GridCases from 'components/organisms/users/cases/GridCases'
import ListCases from 'components/organisms/users/cases/ListCases'
import WorkspaceFilter from 'components/organisms/users/workspace/WorkspaceFilter'
import {
  ADD_CASE,
  DELETE_CASE,
  EDIT_CASE,
  EXPORT_CASE_NOTES,
  GRID_VIEW,
  INITIAL_WORKSPACE_FILTER,
  LIST_VIEW,
  ROLE_SUPER_ADMIN
} from 'constant'
import { staticCases } from 'data/cases'
import useAuth from 'hooks/useAuth'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { errorObj } from 'utils/handleError'
import { showSuccessMessage } from 'utils/showMessage'
const Workspace = () => {
  const navigate = useNavigate()
  const { getAccessToken, platformData, onLogout, handleSetIsFirstLogin } = useAuth()
  const [tcModal, setTcModal] = useState({ show: false, body: null })
  const [filterModal, setFilterModal] = useState(INITIAL_WORKSPACE_FILTER)
  const [createUpdateCaseModal, setCreateUpdateCaseModal] = useState({
    show: false,
    type: ADD_CASE
  })
  const [view, setView] = useState(GRID_VIEW)
  const [search, setSearch] = useState('')

  // FILTER
  function openFilterModal() {
    setFilterModal((prev) => ({ ...prev, show: true }))
  }
  function closeFilterModal() {
    setFilterModal((prev) => ({ ...prev, show: false }))
  }
  function handleSubmitFilter(data) {
    setFilterModal((prev) => ({ ...prev, data: data }))
  }
  function handleSearch(value) {
    setSearch(value)
  }

  // CASE
  function openCaseModal(type, message) {
    setCreateUpdateCaseModal((prev) => ({ ...prev, show: true, type: type, message: message }))
  }
  function closeCaseModal() {
    setCreateUpdateCaseModal((prev) => ({ ...prev, show: false }))
  }
  function handleSubmitCaseModal(data) {
    if (createUpdateCaseModal.type === ADD_CASE) {
      showSuccessMessage('Case Added')
    } else if (createUpdateCaseModal.type === EDIT_CASE) {
      showSuccessMessage('Case Edited')
    } else if (createUpdateCaseModal.type === DELETE_CASE) {
      showSuccessMessage('Case Deleted')
    } else if (createUpdateCaseModal.type === EXPORT_CASE_NOTES) {
      showSuccessMessage('Case Exported')
    }
  }

  // T&C
  useQuery({
    queryKey: ['getTC'],
    queryFn: () => getTC(getAccessToken()),
    onSuccess: (res) => {
      if (res.resData.data !== null) {
        return setTcModal((prev) => ({ ...prev, show: true, body: res.resData.data }))
      }
      return handleSetIsFirstLogin()
    },
    onError: (err) => {
      const errObj = errorObj(err)
    },
    enabled: platformData.role !== ROLE_SUPER_ADMIN && platformData?.isFirstLogin
  })
  const acceptTC = useMutation({
    mutationKey: 'putAcceptTC',
    mutationFn: () => putAcceptTC(getAccessToken()),
    onSuccess: (res) => {
      handleSetIsFirstLogin()
      closeTcModal()
    },
    onError: (err) => {
      const errobj = errorObj(err)
    }
  })
  function handleSubmitTcModal() {
    acceptTC.mutate()
  }
  function closeTcModal() {
    setTcModal((prev) => ({ ...prev, show: false }))
  }

  // GENERAL
  function handleToggleView(value) {
    if (value !== null) {
      return setView(value)
    }
  }

  const newStaticCases = search
    ? staticCases.filter((caseItem) =>
        caseItem?.caseName.toLowerCase().includes(search.toLowerCase())
      )
    : staticCases

  return (
    <div className="overflow-hidden grid grid-cols-1 h-full rounded-3xl">
      <div className="flex flex-col w-full h-full gap-11 pt-4 pl-6 pr-3 bg-[#EDEDEF] border-solid border-[#E3E3E8] border">
        <CaseHeader
          view={view}
          onSearch={handleSearch}
          openFilterModal={openFilterModal}
          onToggleView={handleToggleView}
          totalFilterActive={
            Object.entries(filterModal.data).filter(([_, { value }]) => value !== null).length
          }
          onAddCase={openCaseModal.bind(this, ADD_CASE)}
        />
        {search && newStaticCases.length === 0 && (
          <span className="text-[#828282]">This search produced no results</span>
        )}
        {!search && newStaticCases.length === 0 && <NullCaseComponent />}
        {view === GRID_VIEW && newStaticCases.length > 0 && (
          <GridCases
            data={newStaticCases}
            onViewDetail={(idx) => navigate(`/cases/detail`)}
            onEdit={openCaseModal.bind(this, EDIT_CASE)}
            onDelete={openCaseModal.bind(
              this,
              DELETE_CASE,
              'Are you sure you want to delete the file?\nAll uploaded documents, chats and notes created will be lost.'
            )}
            onExport={openCaseModal.bind(
              this,
              EXPORT_CASE_NOTES,
              'All notes will be exported to a single word file. The export may take several minutes.'
            )}
          />
        )}
        {view === LIST_VIEW && newStaticCases.length > 0 && (
          <ListCases
            data={newStaticCases.slice(0, 9)}
            onViewDetail={(idx) => navigate(`/cases/detail`)}
            onEdit={openCaseModal.bind(this, EDIT_CASE)}
            onDelete={openCaseModal.bind(
              this,
              DELETE_CASE,
              'Are you sure you want to delete the file?\nAll uploaded documents, chats and notes created will be lost.'
            )}
            onExport={openCaseModal.bind(
              this,
              EXPORT_CASE_NOTES,
              'All notes will be exported to a single word file. The export may take several minutes.'
            )}
          />
        )}
        {tcModal.show && (
          <TCModal
            isLoading={acceptTC.isLoading}
            body={tcModal.body}
            show={tcModal.show}
            onClose={onLogout}
            onSubmit={handleSubmitTcModal}
            onLogout={onLogout}
          />
        )}
        {filterModal.show && (
          <WorkspaceFilter
            onClose={closeFilterModal}
            data={filterModal.data}
            show={filterModal.show}
            onSubmit={handleSubmitFilter}
          />
        )}
        {createUpdateCaseModal.show && (
          <CaseModal
            show={createUpdateCaseModal.show}
            data={{}}
            message={createUpdateCaseModal.message}
            type={createUpdateCaseModal.type}
            onClose={closeCaseModal}
            onSubmit={handleSubmitCaseModal}
          />
        )}
      </div>
    </div>
  )
}

export default Workspace
