import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { $generateNodesFromDOM, $generateHtmlFromNodes } from '@lexical/html';
import { $insertNodes } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Controller } from "react-hook-form";

import CustomAutoLinkPlugin from "components/atoms/admin/termconditions/CustomAutoLinkPlugin";
import LinkNodePlugin from "components/atoms/admin/termconditions/LinkNodePlugin";
import ToolbarPlugin from "components/atoms/admin/termconditions/ToolbarPlugin";
import ErrorMessage from "components/atoms/ErrorMessage";

function Placeholder() {
  return <div className="text-white opacity-50 overflow-hidden absolute overflow-ellipsis top-[7px] left-[10px] text-[15px] inline-block pointer-events-none">Please enter the paragraph</div>;
}

const MyOnChangePlugin = ({ onHtmlChanged, }) => {
  const [editor] = useLexicalComposerContext();
  return (
    <OnChangePlugin
      onChange={(editorState) => {
        editorState.read(() => {
          onHtmlChanged($generateHtmlFromNodes(editor));
        });
      }}
    />
  );
}

const RichTextEditor = ({ control, label, name, errors, isRequired = false, inArray = false, ...inputProps }) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <LexicalComposer initialConfig={{
            theme: {
              link: 'editor-link',
              text: {
                bold: 'font-bold',
                italic: 'italic',
                underline: 'underline',
              },
            },
            onError(error) {
              throw error;
            },
            editorState: (editor) => {
              editor.update(() => {
                const parser = new DOMParser();
                const dom = parser.parseFromString(field.value, "text/html");
                const nodes = $generateNodesFromDOM(editor, dom);
                $insertNodes(nodes);
              })
            },
            // Any custom nodes go here
            nodes: [
              AutoLinkNode,
              LinkNode
            ]
          }}>
            <div>
              <span className={`${inArray ? errors ? '!text-red-500' : 'text-[#FFFFFFA6]' : errors[name] ? '!text-red-500' : 'text-[#FFFFFFA6]'}`}>
                {label}
              </span>
              <ToolbarPlugin />
              <div className="relative group mt-0 mb-0 rounded-[4px] border-0 h-[140px]">
                <RichTextPlugin
                  contentEditable={<ContentEditable
                    className='overflow-y-scroll bg-[#191919] relative text-[15px] border-t-0 border-x-0 rounded-md outline-none h-full p-2 caret-white text-white'
                  />}
                  placeholder={<Placeholder />}
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <div className='absolute bottom-0 left-[50%] w-[0px] h-[2px] bg-[#1976d2] transition-all duration-150 group-focus-within:w-[100%] ease-[ease] group-focus-within:ease-[ease] group-focus-within:left-0 group-focus-within:transition-all group-focus-within:duration-150' />
                <HistoryPlugin />
                <AutoFocusPlugin />
                <LinkPlugin />
                <CustomAutoLinkPlugin />
                <LinkNodePlugin />
                <MyOnChangePlugin
                  onHtmlChanged={(html) => field.onChange(html)}
                />
              </div>
            </div>
          </LexicalComposer>
        )}
      />
      {inArray && errors ? <ErrorMessage errorMessage={errors.message} /> : null}
      {!inArray && errors[name] ? <ErrorMessage errorMessage={errors[name].message} /> : null}
    </div>
  );
}

export default RichTextEditor