import { apiGet } from 'api/api-get'
import { apiPut } from 'api/api-put'
import { urls } from 'api/api-url'

export const updateAppInformation = (token, key, data) => {
  return apiPut({ url: `${urls.appInformation.appInformation}/${key}`, token: token, data: data })
}

export const getAppInformation = (token) => {
  return apiGet({ url: urls.appInformation.appInformation, token: token })
}
