import { createSlice } from '@reduxjs/toolkit'

const initialState = { access_token: null, role: null, profile: {}, isFirstLogin: true }

const adminSlice = createSlice({
  name: 'admin',
  initialState: initialState,
  reducers: {
    setProfile(state, action) {
      state.profile = action.payload
    },
    loginBackOffice(state, action) {
      state.access_token = action.payload.access_token
      state.role = action.payload.role
      state.profile = action.payload.profile
    },
    setEmailAddress(state, action) {
      state.profile.email = action.payload.email
      state.profile.updated_at = action.payload.updated_at
    },
    logoutBackOffice(state) {
      Object.assign(state, initialState)
    }
  }
})

export const adminActions = adminSlice.actions

export const adminReducer = adminSlice.reducer
